import {
  BarDatum, BarItemProps, BarTooltipProps, ComputedBarDatum,
} from '@nivo/bar';
import { classes, Colors } from '@whoop/web-components';
import { ReactElement } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useTooltip } from '@nivo/tooltip';
import styles from './bars.module.scss';
import { BarLabel } from './barLabel/barLabel';

type HighlightLineProps = {
  width: number;
  hoverLineHeight: number;
  index: number;
};

function HighlightLine({ width, hoverLineHeight, index }: HighlightLineProps) {
  return (
    <path
      d={`M${width / 2} 0 L${width / 2} -${hoverLineHeight}`}
      stroke={Colors.blackAlpha900}
      strokeDasharray="5,5"
      strokeWidth="1"
      fill="none"
      aria-label={`hover-line-${index}`}
    />
  );
}

export function LabeledBarComponent(
  barComp: ComputedBarDatum<BarDatum>,
  tooltip: React.FC<BarTooltipProps<BarDatum>>,
  dayHighlighted: string,
  setDayHighlighted: (dayHighlighted: string) => void,
  lastIndex: number,
  metricName: string,
  color?: string,
  stackLayerIndex: number = 0,
  customFormat: (val: number) => string = null,
): ReactElement<BarItemProps<BarDatum>> {
  const { data, ...bar } = barComp;

  const { showTooltipAt, hideTooltip } = useTooltip();
  const highlightVal = dayHighlighted === data.indexValue;
  // adds alpha of 30
  const unfocusedColor = color.startsWith('rgb') ? color : `${color}30`;

  // applies overflow positioning
  const tooltipXVal = data.index >= lastIndex - 1
    ? bar.absX : Math.round(bar.absX + bar.width) + 5;
  const handleMouseEnter = () => {
    setDayHighlighted(data.indexValue as string);
    showTooltipAt(
      tooltip({ ...bar, ...data }),
      [tooltipXVal, 0],
    );
  };

  const handleMouseLeave = () => {
    setDayHighlighted(null);
    hideTooltip();
  };

  // Get the height of the graph to calculate the hover line height
  const barComponent = document.getElementById(bar.label);
  // This is getting the parent SVG of the bar which is the graph as a whole
  const parentSVG = barComponent?.parentElement?.parentElement;
  const hoverLineHeight = parentSVG?.clientHeight ?? 0;

  const showHighlightLine = () => {
    const topBarComponentKey = data?.data?.topBarComponentKey;
    const barKey = bar.key;
    if (topBarComponentKey) {
      if (topBarComponentKey === barKey) {
        return (
          <HighlightLine width={bar.width} hoverLineHeight={hoverLineHeight} index={bar.index} />
        );
      }
      return null;
    }
    return (
      <HighlightLine width={bar.width} hoverLineHeight={hoverLineHeight} index={bar.index} />
    );
  };

  return (
    <g
      transform={`translate(${bar.x}, ${stackLayerIndex ? bar.y - stackLayerIndex * 2 : bar.y})`}
      onMouseEnter={(): void => handleMouseEnter()}
      onMouseLeave={(): void => handleMouseLeave()}
      id={bar.label}
    >
      {!dayHighlighted && (
      <BarLabel
        customFormat={customFormat}
        width={bar.width}
        color={color}
        topBarComponentKey={data?.data?.topBarComponentKey}
        value={data?.data?.metric_value ?? data?.value}
        barKey={bar.key}
      />
      )}
      {/* if the bars are stacked only the first bar layer should show the path line */}
      {highlightVal && (showHighlightLine())}
      <rect
        role="img"
        aria-label={`bar-${bar.index}`}
        width={bar.width}
        height={bar.height}
        fill={dayHighlighted && !highlightVal ? unfocusedColor : color}
        strokeWidth="1"
      />
    </g>
  );
}

export function CustomPillarBreakdownBar(
  { bar: { data, ...bar }, tooltip, onClick }: BarItemProps<BarDatum>,
  showHoverState: boolean,
) {
  const { showTooltipAt, hideTooltip } = useTooltip();
  const tooltipYVal = 100;

  let tooltipXVal = bar.absX + bar.width / 2;
  const handleMouseEnter = () => {
    const barComponent = document.getElementById(bar.label);
    // This gets the positioning of the bar on the screen
    const barPagePositioning = barComponent.getBoundingClientRect();
    const windowWidth = window.innerWidth;
    const tooltipOffset = 50;

    // These values are to prevent the tooltip from going off the screen
    // If the bar starts at greater than 85% of the screen,
    // we move the tooltip to the left by subtracting the tooltipOffset
    if (barPagePositioning.left / windowWidth > 0.85) {
      tooltipXVal = bar.absX + bar.width / 2 - tooltipOffset;
    }
    // If the bar starts at less tha 15% of the screen,
    // we move the tooltip to the right by adding the tooltipOffset
    if (barPagePositioning.left / windowWidth < 0.15) {
      tooltipXVal = bar.absX + bar.width / 2 + tooltipOffset;
    }
    showTooltipAt(tooltip({ ...bar, ...data }), [tooltipXVal, tooltipYVal]);
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  return (
    <g
      transform={`translate(${bar.x}, ${bar.y})`}
      onMouseEnter={(): void => handleMouseEnter()}
      onMouseLeave={(): void => handleMouseLeave()}
      id={bar.label}
    >
      <rect
        aria-label={`bar-${bar.label}`}
        width={bar.width}
        height={bar.height}
        fill={bar.color}
        rx={2}
        className={classes(showHoverState && styles.pillarBreakdownBar)}
        onClick={(event) => onClick && onClick({ ...data, color: bar.color }, event)}
      />
    </g>
  );
}

export default LabeledBarComponent;
