import Card from 'progress/components/Card/card';
import { formatDate, getFormattedRangeDay } from 'progress/profile/profileUtils';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import {
  Metrics,
  MetricType,
  METRIC_DISPLAY_NAMES,
  METRIC_TO_PILLAR_MAP,
  Pillars,
  SleepMetricDetails,
  SleepMetricDetailsType,
  SleepMetricType,
} from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import { createDayBarLineGraphData } from 'dataVisualizations/utils/utils';
import DayBarChart, { DEFAULT_KEY_VALUE } from 'dataVisualizations/dayBarChart/dayBarChart';
import DayLineChart from 'dataVisualizations/dayLineChart/dayLineChart';
import { BarData, LineData } from 'dataVisualizations/types/types';
import {
  METRIC_NUMBER_FORMATTER, METRIC_LABEL_FORMATTER,
  METRIC_Y_AXIS_FORMATTER, METRIC_TO_BAR_Y_AXIS_VALS, METRIC_TO_LINE_Y_AXIS_VALS,
} from 'progress/utils/numberFormatter';
import { Colors } from '@whoop/web-components';
import styles from './statisticTrendChart.module.scss';

// For pillar trend bar chart and line graphs
const PILLAR_TO_TREND_COLOR = {
  [Pillars.STRAIN]: Colors.strainBlue,
  [Pillars.RECOVERY]: Colors.recoveryBlue,
  [Pillars.SLEEP]: Colors.sleepBlue,
};

const SLEEP_DURATION_KEYS = [
  SleepMetricDetailsType.SLOW_WAVE_DURATION,
  SleepMetricDetailsType.REM_DURATION,
  SleepMetricDetailsType.LIGHT_SLEEP_DURATION,
  SleepMetricDetailsType.WAKE_DURATION,
];

type StatisticTrendChartProps = {
  selectedMetricType: MetricType;
};

export const getTopOfBarKey = (metric: Metrics) => {
  const sleepMetricDetails = metric.metric_details as SleepMetricDetails;
  const key: any = {
    topBarComponentKey: null,
  };
  // check for metric_details
  // there aren't any stacked bar charts for data that doesn't have metric details
  if (sleepMetricDetails) {
    // loop through the arrays backwards
    // since the last index in the key is what Nivo will try and put on the top of the bar graph
    for (let i = SLEEP_DURATION_KEYS.length - 1; i >= 0; i -= 1) {
      // if the metric_details object contains a value for the key
      // it should be the top of the bar graph since we are traversing from the top down
      if (sleepMetricDetails[SLEEP_DURATION_KEYS[i]]) {
        return {
          topBarComponentKey: `${
            SLEEP_DURATION_KEYS[i]
          }.${formatDate(metric.cycle_start_day.join('-'))}`,
        };
      }
    }
  }
  // if none of these conditions are met there is no topBarComponentKey
  return key;
};

function StatisticTrendChart({ selectedMetricType }: StatisticTrendChartProps) {
  const { dateRange } = useDateRange();
  const detailDate = getFormattedRangeDay(dateRange);
  const pillar = METRIC_TO_PILLAR_MAP[selectedMetricType];
  const { data, isFetching } = useMetric(selectedMetricType, dateRange);

  const numberFormatter = METRIC_NUMBER_FORMATTER[selectedMetricType];
  const labelFormatter = METRIC_LABEL_FORMATTER[selectedMetricType];
  const metricDisplayName = METRIC_DISPLAY_NAMES[selectedMetricType];
  const color = PILLAR_TO_TREND_COLOR[pillar];

  const isSleepDuration = selectedMetricType === SleepMetricType.DURATION;

  const dataPoints = createDayBarLineGraphData(
    data,
    numberFormatter,
    isSleepDuration && getTopOfBarKey,
  );

  return (
    <Card id={`${selectedMetricType}-trend`} loading={isFetching} showBackground={false}>
      <CardHeader title={metricDisplayName} details={detailDate} />
      <div className={styles.rangeChart}>
        {dataPoints?.length <= 7 && (
          <DayBarChart
            data={dataPoints as BarData[]}
            gridYValues={METRIC_TO_BAR_Y_AXIS_VALS[selectedMetricType](data)}
            color={color}
            metricName={metricDisplayName}
            customLabelFormat={labelFormatter}
            customYAxisFormat={METRIC_Y_AXIS_FORMATTER[selectedMetricType]}
            isStackedBarChart={isSleepDuration}
            keys={
              isSleepDuration
                ? SLEEP_DURATION_KEYS
                : DEFAULT_KEY_VALUE
            }
          />
        )}
        {dataPoints?.length > 7 && (
          <DayLineChart
            data={dataPoints as LineData[]}
            gridYValues={METRIC_TO_LINE_Y_AXIS_VALS[selectedMetricType](data)}
            color={color}
            metricName={metricDisplayName}
            customLabelFormat={labelFormatter}
            customYAxisFormat={METRIC_Y_AXIS_FORMATTER[selectedMetricType]}
          />
        )}
      </div>
    </Card>
  );
}

export default StatisticTrendChart;
