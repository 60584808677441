import useGroupLanding from 'api/hooks/useGroupLanding';
import GroupsTable from 'insights/components/groupsTable/groupsTable';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import LoadingSkeleton from 'insights/components/loadingSkeleton/loadingSkeleton';
import styles from './groupsPage.module.scss';

function GroupsPage() {
  const { dateRange } = useDateRange();
  const { data: groupLanding, isLoading } = useGroupLanding(dateRange);

  if (isLoading) {
    return (
      <>
        <div className={styles.headerLoading}>
          <LoadingSkeleton isLoading={isLoading} />
        </div>
        { Array.from({ length: 5 }, (item, index) => (
          <div className={styles.rowLoading} key={index}>
            <LoadingSkeleton isLoading={isLoading} />
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={styles.pageBackground}>
      <GroupsTable data={groupLanding?.groups} />
    </div>
  );
}

export default GroupsPage;
