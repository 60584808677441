import {
  useEffect, useMemo, useState,
} from 'react';
import {
  Column,
  Row,
  TableCommonProps,
} from 'react-table';
import { UseStatePropType } from 'types/useStatePropType';
import { HubUser } from 'types/hub';
import { SELECTION_COLUMN } from 'tableUtils/tableColumns';
import {
  ACTION_BAR,
} from 'constants/accountTable';
import GenericWarning from 'components/genericWarning';
import Dropdown from 'components/dropdown';
import { HEADING_4 } from 'components/text';
import { Icon, Button } from '@whoop/web-components';
import { initialPageSizes, HubMemberPageOption, initialStatusOptions } from 'hub/hubUtils';
import CheckIcon from '@material-ui/icons/Check';
import Table from 'components/table';
import HubPageDropdown from './hubPageDropdown/hubPageDropdown';
import {
  PATIENT_ID_COLUMN,
  SENSOR_ID_COLUMN,
  HUB_MEMBER_STATUS_COLUMN,
  LAST_CONNECTED_COLUMN,
  LAST_UPLOADED_COLUMN,
  BATTERY_LEVEL_COLUMN,
  OPTIONS_COLUMN,
} from './hubMembersTableColumns';
import styles from './hubMembersTable.module.scss';

type HubMembersTableProps = {
  loading: boolean;
  data: HubUser[];
  selectedRows: Row<HubUser>[];
  setSelectedRows: UseStatePropType<Row<HubUser>[]>;
  setArchiveMemberModal: UseStatePropType<boolean>;
};

function HubMembersTable({
  data,
  loading,
  selectedRows,
  setSelectedRows,
  setArchiveMemberModal,
}: HubMembersTableProps) {
  const columns: Column<HubUser>[] = useMemo(() => [
    SELECTION_COLUMN,
    PATIENT_ID_COLUMN,
    SENSOR_ID_COLUMN,
    HUB_MEMBER_STATUS_COLUMN,
    LAST_CONNECTED_COLUMN,
    LAST_UPLOADED_COLUMN,
    BATTERY_LEVEL_COLUMN,
    OPTIONS_COLUMN,
  ], []);

  const [pageSizeOptions, setPageSizeOptions] = useState<HubMemberPageOption[]>(initialPageSizes);

  const [statusOptions, setStatusOptions] = useState<HubMemberPageOption[]>(initialStatusOptions);
  const [pageSize, setPageSize] = useState<number>(Number(initialPageSizes[0].name));
  const [visibleRows, setVisibleRows] = useState<Row<HubUser>[]>([]);

  const currentPageSize = useMemo(
    () => pageSizeOptions.find((item) => item.active).name,
    [pageSizeOptions],
  );
  const currentPageStatus = useMemo(
    () => {
      const option = statusOptions.find((item) => item.active);
      return option.name;
    },
    [statusOptions],
  );

  useEffect(() => {
    let newStatusOptions = [...statusOptions];
    newStatusOptions = newStatusOptions.map((option: HubMemberPageOption) => {
      const newOption = option;
      let displayVal = newOption.name;
      if (newOption.name === 'active') {
        displayVal = 'activated';
      }
      newOption.display_name = `${displayVal} (${data.filter((item) => item.status === newOption.name).length})`;
      return newOption;
    });
    setStatusOptions(newStatusOptions);
  }, []);

  const pageData: HubUser[] = useMemo(
    () => data.filter((item) => currentPageStatus.includes(item.status)),
    [currentPageStatus],
  );

  const rowsPerPageFilter = (item: HubMemberPageOption) => {
    let newPageSize: HubMemberPageOption[] = [...pageSizeOptions];
    newPageSize = newPageSize.map((row: HubMemberPageOption) => {
      const newRow = row;
      newRow.active = false;
      return newRow;
    });
    const currentSelection = newPageSize.find((row: HubMemberPageOption) => row.name === item.name);
    currentSelection.active = true;
    setPageSizeOptions(newPageSize);
    setPageSize(parseInt(currentSelection.name, 10));
  };

  const statusFilter = (item: HubMemberPageOption) => {
    let newStatuses: HubMemberPageOption[] = [...statusOptions];
    newStatuses = newStatuses.map((option: HubMemberPageOption) => {
      const status = option;
      status.active = false;
      return status;
    });
    const current = newStatuses.find((status: HubMemberPageOption) => status.name === item.name);
    current.active = true;
    setStatusOptions(newStatuses);
  };

  const selectedActiveRows = selectedRows.filter(
    (row) => row.original.status === 'active',
  ).length;
  const selectedPendingRows = selectedRows.filter(
    (row) => row.original.status === 'pending',
  ).length;
  const selectedArchivedRows = selectedRows.filter(
    (row) => row.original.status === 'archived',
  ).length;

  const actionButtons = (
    <div className={styles.tableActions}>
      <div className={styles.dropdowns}>
        <Dropdown
          button={(
            <div className={styles.linkBtn}>
              <HEADING_4>
                {`${ACTION_BAR.STATUS}: ${currentPageStatus === 'active' ? 'activated' : currentPageStatus}`}
              </HEADING_4>
              <Icon name="tiny_triangle_down" className={styles.logo} />
            </div>
          )}
          buttonProps={{ variant: 'link', className: styles.dropdownBtn }}
          dropdownStyles={{ borderTop: '3px solid black' }}
          closeOnClick
        >
          <HubPageDropdown
            items={statusOptions}
            activeIcon={<CheckIcon />}
            onItemClick={statusFilter}
          />
        </Dropdown>
        <Dropdown
          button={(
            <div className={styles.linkBtn}>
              <HEADING_4>
                {`${ACTION_BAR.ROWS_PER_PAGE}: ${currentPageSize}`}
              </HEADING_4>
              <Icon name="tiny_triangle_down" className={styles.logo} />
            </div>
          )}
          buttonProps={{ variant: 'link', className: styles.dropdownBtn }}
          dropdownStyles={{
            borderTop: '3px solid black',
            width: '120px',
            minWidth: '120px',
            transform: 'translate(-44px)',
          }}
          closeOnClick
        >
          <div className={styles.numberInput}>
            <HubPageDropdown
              items={pageSizeOptions}
              activeIcon={<CheckIcon />}
              onItemClick={rowsPerPageFilter}
            />
          </div>
        </Dropdown>
      </div>
      <div className={styles.buttons}>
        {selectedActiveRows === 1 && (
          <Button
            label="ASSIGN NEW SENSOR"
            size="small"
            onClick={() => console.log('Assign new sensor')}
            variant="normal"
            theme="enterprise"
            className={styles.assignNewSensor}
            disabled
          />
        )}
        {(selectedActiveRows > 0 || selectedPendingRows > 0) && (
          <Button
            label={`ARCHIVE MEMBER${selectedActiveRows > 1 || selectedPendingRows > 1 ? 'S' : ''}`}
            size="small"
            onClick={() => setArchiveMemberModal(true)}
            variant="primary"
            theme="enterprise"
            className={styles.archiveMember}
          />
        )}
        {selectedActiveRows === 0 && selectedPendingRows === 0 && selectedArchivedRows === 0 && (
          <Button
            label="ADD NEW MEMBERS"
            size="small"
            onClick={() => console.log('Add hub member')}
            variant="primary"
            theme="enterprise"
            className={styles.addNewMembers}
            disabled
          />
        )}
      </div>
    </div>
  );

  const membershipTypesSelected = [
    selectedActiveRows,
    selectedPendingRows,
    selectedArchivedRows,
  ].filter((x) => x > 0);

  const getRowStyles = (): TableCommonProps => ({
    style: {
      height: '80px',
    },
  });

  return (
    <div className={styles.hubMembersTable}>
      {membershipTypesSelected.length > 1 && (
        <GenericWarning>
          Heads up, not all actions are applicable to your selected memberships.
          Taking certain actions will only affect the applicable selections.
        </GenericWarning>
      )}
      <Table
        columns={columns}
        data={pageData}
        initialSortByField="patient_id_number"
        loading={loading}
        tableName="hubs"
        heading="hub members"
        unit="hub"
        customSubHeading={`${visibleRows.length} member${visibleRows.length === 1 ? '' : 's'} associated to hub`}
        searchPlaceholder="Search for a patient ID or sensor ID..."
        noDataFoundMessage="No search results or data."
        tableLength={pageSize}
        getAdditionalRowProps={getRowStyles}
        optionalControls={actionButtons}
        setSelectedRows={setSelectedRows}
        setVisibleRows={setVisibleRows}
      />
    </div>
  );
}

export default HubMembersTable;
