import { useDateRange } from 'progress/profile/hooks/useDateRange';
import { getDateRangeString } from 'progress/profile/profileUtils';
import {
  MetricType, Pillars, PILLAR_NAMES, PILLAR_TO_METRIC_TYPE,
} from 'types/analytics';
import Card from 'progress/components/Card/card';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import PillarStatTile from 'progress/components/pillarStatTile/pillarStatTile';
import styles from './statisticsCard.module.scss';

type StatisticsCardProps = {
  pillar: Pillars;
  selectedMetricType?: MetricType;
  setSelectedMetricType?: (metricType: MetricType) => void;
};

function StatisticsCard({
  pillar,
  selectedMetricType,
  setSelectedMetricType,
}: StatisticsCardProps) {
  const { dateRange, comparisonDateRange } = useDateRange();
  const { formattedComparison } = getDateRangeString(dateRange, comparisonDateRange);
  return (
    <Card id={`${pillar}-stats`} loading={false} overflow>
      <CardHeader
        title={`${PILLAR_NAMES[pillar]} Statistics`}
        details={formattedComparison}
        sticky
      />
      <div className={styles.statTiles}>
        {Object.values(PILLAR_TO_METRIC_TYPE[pillar]).map((metric: MetricType) => (
          <PillarStatTile
            key={metric}
            metric={metric}
            isSelected={metric === selectedMetricType}
            setSelectedMetricType={() => setSelectedMetricType && setSelectedMetricType(metric)}
          />
        ))}
        {/* Add right padding to the last card to fix the no padding issue on scroll */}
        <div style={{ paddingRight: '16px' }} />
      </div>
    </Card>
  );
}

export default StatisticsCard;
