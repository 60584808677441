import { useEffect, useState } from 'react';
import {
  useLocation, useParams, createSearchParams, Outlet, useNavigate, useSearchParams,
} from 'react-router-dom';
import {
  classes,
  RadioToggle,
  Container, Row, Col,
} from '@whoop/web-components';
import DatePicker from 'components/datePicker/datePicker';
import dayjs from 'dayjs';
import {
  OVERVIEW_TAB, Period, Pillars, ProgressMemberOverview,
} from 'types/analytics';
import ProfileButton from 'components/profileButton/profileButton';
import { analyticsClient } from 'api';
import Tabs from 'components/tabs/tabs';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import useInsightsRoutes from 'insights/insightsRoutes/useInsightsRoutes';
import styles from './profile.module.scss';
import { daysBetweenRange, formatDate } from './profileUtils';
import { useDateRange } from './hooks/useDateRange';

type UrlParams = {
  userId: string;
  accountId: string;
};

function Profile() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { isInPrivacyLevel } = usePrivacy();
  const { accountId, userId } = useParams<UrlParams>();
  const { dateRange, setDateRange } = useDateRange();
  const onInsightsTab = useInsightsRoutes();

  const [pillar, setPillar] = useState<string>();
  const [profileData, setProfileData] = useState<ProgressMemberOverview>();
  const [periodSelected, setPeriodSelected] = useState<Period>(Period.DAILY);
  const tab = location.pathname.split('/')[location.pathname.split('/').length - 1];

  const showProfileBreakdownOptions = () => {
    const profileBreakdownOptions = [
      { tab: OVERVIEW_TAB },
    ];

    if (isInPrivacyLevel(PrivacyLevel.performance_metrics)) {
      profileBreakdownOptions.push({ tab: Pillars.STRAIN });
      profileBreakdownOptions.push({ tab: Pillars.RECOVERY });
    }

    if (isInPrivacyLevel(PrivacyLevel.all_metrics)) {
      profileBreakdownOptions.push({ tab: Pillars.SLEEP });
    }

    return profileBreakdownOptions;
  };

  const radioToggleOptions = [
    {
      label: Period.DAILY,
      value: Period.DAILY,
    },
    {
      label: Period.SEVEN_DAYS,
      value: Period.SEVEN_DAYS,
    },
    {
      label: Period.THIRTY_DAYS,
      value: Period.THIRTY_DAYS,
    },
  ];

  const getMemberOverview = async () => {
    try {
      const userProfile: ProgressMemberOverview = await analyticsClient.getMemberOverviewV2(
        parseInt(userId, 10),
      );
      setProfileData(userProfile);
    } catch (e) {
      navigate('/404');
    }
  };

  const selectPeriodFromDateRange = () => {
    const daysBetweenDates = daysBetweenRange(dateRange);
    if (daysBetweenDates === 7) {
      setPeriodSelected(Period.SEVEN_DAYS);
    } else if (daysBetweenDates === 30) {
      setPeriodSelected(Period.THIRTY_DAYS);
    } else {
      // daysBetweenDates is not 7 days or 30 days so set daily as default
      setPeriodSelected(Period.DAILY);
    }
  };

  useEffect(() => {
    getMemberOverview();
    showProfileBreakdownOptions();
  }, []);

  useEffect(() => {
    selectPeriodFromDateRange();
  }, [dateRange]);

  useEffect(() => {
    setPillar(tab);
    // if profile strain link is put in url check if user has permissions
    // if the user's privacy level is below primary_metrics, getMemberOverview will nav to 404
    const isInPrimaryMetrics = isInPrivacyLevel(PrivacyLevel.primary_metrics);
    if (!isInPrimaryMetrics) {
      navigate('/404');
    }
    // if the user's privacy level is above primary_metrics, but below performance_metrics
    // nav to forbidden
    const isNotGreaterThanPerformanceMetrics = !isInPrivacyLevel(PrivacyLevel.performance_metrics);
    const isNotAllMetrics = !isInPrivacyLevel(PrivacyLevel.all_metrics);
    if ((tab === Pillars.STRAIN || tab === Pillars.RECOVERY)
    && isNotGreaterThanPerformanceMetrics) {
      navigate('/forbidden');
    }

    if ((tab === Pillars.SLEEP)
    && isNotAllMetrics) {
      navigate('/forbidden');
    }
  }, [tab]);

  const handleNavigate = (tabOption: string) => {
    const params = new URLSearchParams(searchParams);
    params.set('from', formatDate(dateRange.from));
    if (dateRange.to) {
      params.set('to', formatDate(dateRange.to));
    }
    const queryParams = createSearchParams(params).toString();
    const profileURLSection = onInsightsTab ? `insights/profile/${userId}` : `progress/${userId}/profile`;

    navigate(`/accounts/${accountId}/${profileURLSection}/${tabOption}?${queryParams}`);
  };

  const togglePeriod = (period: Period) => {
    setPeriodSelected(period);
    let newRange;
    if (period === Period.DAILY) {
      newRange = { from: dayjs().startOf('day').toDate() };
    } else {
      newRange = {
        from: dayjs().subtract(period === Period.SEVEN_DAYS ? 6 : 29, 'days').startOf('day').toDate(),
        to: dayjs().startOf('day').toDate(),
      };
    }

    setDateRange(newRange);
  };

  return (
    <Container className={styles.profileContainer} fluid>
      {profileData && (
        <Row className={styles.profileHeader}>
          <Col xs={12}>
            <Row className={styles.profileHeaderTopRow} middle="xs" between="xs">
              <Col md={2} sm={12}>
                <ProfileButton
                  user={profileData.member_profile}
                  groupList={profileData.cohort_member_details_list}
                  getMemberOverview={getMemberOverview}
                />
              </Col>
              <Col md={7} sm={12}>
                <div className={styles.datePickerContainer} data-theme="light">
                  <DatePicker
                    selectSingleDay={periodSelected === Period.DAILY}
                    required
                    autoSelectRange={periodSelected}
                    currentRange={dateRange}
                    applyRange={setDateRange}
                    styleDatePickerBox={classes(styles.datePickerBox, periodSelected === Period.DAILY ? styles.singleDaySelected : '')}
                    disableBefore={new Date(profileData?.member_profile.data_available_date)}
                  />
                  <RadioToggle
                    options={radioToggleOptions}
                    value={periodSelected}
                    onChange={togglePeriod}
                    size="medium"
                    useExternalState
                    name="period"
                    theme="square"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <div className={styles.profileBreakdownOptionContainer}>
                  <Tabs
                    tabList={showProfileBreakdownOptions()}
                    selectedTab={pillar}
                    onClick={(selectedTab) => handleNavigate(selectedTab)}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      {profileData && <Outlet context={{ periodSelected }} />}
    </Container>
  );
}

export default Profile;
