import { levels } from 'context/privacy-context';
import {
  collaborators, currentDashboardUser,
} from 'mocks/store/dashboardUsers';
import { AccountConfiguration, reidentificationTokenKey } from 'types/admin';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { Collaborator, InviteStatus } from 'types/collaborator';
import { Profile } from 'types/auth';
import {
  assetsOnHand,
  baseAccount, configurations, reidentificationTokenBody,
} from '../store/data';
import { getSeats } from './seatActions';

type InviteCollabType = {
  email: string;
  privacy_level: PrivacyLevel;
  display_role: string;
  used: boolean;
  status: InviteStatus;
  is_primary_owner: boolean;
  salesforce_account_id: string;
  role: DashboardRole;
};

type UpdateCollabType = {
  display_role: string;
  privacy_level: PrivacyLevel;
  role: DashboardRole;
  user_id: number;
};

type UpdateInvitedCollabType = {
  display_role: string;
  privacy_level: PrivacyLevel;
  role: DashboardRole;
  status: InviteStatus;
};

export const getAccount = () => {
  const seats = getSeats();
  const numActiveSeats = seats.filter((seat) => seat.status === 'active').length;
  return {
    ...baseAccount,
    invited_seats: seats.filter((seat) => seat.status === 'invited').length,
    pending_seats: seats.filter((seat) => seat.status === 'pending').length,
    active_seats: numActiveSeats,
    num_compliant: numActiveSeats - 1,
  };
};

export const getDashboardUser = () => currentDashboardUser;

export const getAvailableBulkLicenses = () => ({
  total_licenses: baseAccount.total_licenses,
  available_bulk_license_count: assetsOnHand - getSeats().filter((s) => s.status === 'active' || s.status === 'pending' || (s.status === 'invited' && s.shipping_type === 'bulk')).length,
});

export const getConfigurations = () => configurations;

export const setConfigurations = (configRequest: AccountConfiguration) => {
  configurations.concealed_feature_enabled = configRequest.concealed_feature_enabled;
  configurations.hub_enabled = configRequest.hub_enabled;
  configurations.strap_onboarding_enabled = configRequest.strap_onboarding_enabled;
  configurations.bluetooth_roaming = configRequest.bluetooth_roaming;
  configurations.deidentified = configRequest.deidentified;
  configurations.privacy_ceiling_level = configRequest.privacy_ceiling_level;
  configurations.concealed = configRequest.concealed;
  configurations.opt_out_enabled = configRequest.opt_out_enabled;

  return configurations;
};

export const getCollaborators = () => collaborators;

const getIndexOfActiveCollaborator = (array: Collaborator[], userId: number) => {
  const collaborator = array.find((collab) => collab.user_id === parseInt(String(userId), 10));
  return array.indexOf(collaborator);
};

const getIndexOfInvitedCollaborator = (array: Collaborator[], email: string) => {
  const collaborator = array.find((collab) => collab.email === email);
  return array.indexOf(collaborator);
};

export const updateInvitedCollaborator = (email: string, payload: UpdateInvitedCollabType) => {
  let collaboratorToUpdate = collaborators.find((collab) => collab.email === email);
  const collaboratorToUpdateIndex = getIndexOfInvitedCollaborator(collaborators, email);
  collaboratorToUpdate = { ...collaboratorToUpdate, ...payload };

  collaborators[collaboratorToUpdateIndex] = collaboratorToUpdate;
};

export const updateCollaborators = (res: UpdateCollabType) => {
  if (currentDashboardUser.user_id === res.user_id) {
    currentDashboardUser.privacy_level = res.privacy_level;
    currentDashboardUser.role = res.role;
    currentDashboardUser.display_role = res.display_role;
  }

  let collaboratorToUpdate = collaborators.find((collab) => collab.user_id === res.user_id);
  const collaboratorToUpdateIndex = getIndexOfActiveCollaborator(collaborators, res.user_id);

  collaboratorToUpdate = { ...collaboratorToUpdate, ...res };

  collaborators[collaboratorToUpdateIndex] = collaboratorToUpdate;
};

export const removeCollaboratorFromAccount = (userId: number) => {
  collaborators.splice(getIndexOfActiveCollaborator(collaborators, userId), 1);
};

export const removeInvitedCollaboratorFromAccount = (email: string) => {
  collaborators.splice(getIndexOfInvitedCollaborator(collaborators, email), 1);
};

export const getCollaboratorLink = () => ({ collaborator_invite_link: 'demo-registration-url' });

export const inviteCollaborator = (data: InviteCollabType) => {
  // Check if re-sending existing invite
  if (collaborators.find((collab) => collab.email === data.email)) {
    return;
  }

  collaborators.push({
    email: data.email,
    display_role: data.display_role,
    role: data.role,
    privacy_level: data.privacy_level,
    status: InviteStatus.invited,
  });
};

export const getAllAccessPrivacy = () => {
  const ceilingPrivacy = levels[configurations.privacy_ceiling_level];
  const userPrivacy = levels[currentDashboardUser.privacy_level];
  if (userPrivacy < ceilingPrivacy) {
    return { privacy_level: currentDashboardUser.privacy_level };
  }
  return { privacy_level: configurations.privacy_ceiling_level };
};

export const setUserProfile = (userProfile: Profile) => {
  currentDashboardUser.name = `${userProfile.first_name} ${userProfile.last_name}`;
  currentDashboardUser.email = `${userProfile.first_name.toLowerCase()}.${userProfile.last_name.toLowerCase()}@whoop.com`;
  currentDashboardUser.user_id = userProfile.id;
  currentDashboardUser.avatar_url = userProfile.avatar_url;
  currentDashboardUser.privacy_level = PrivacyLevel.all_metrics;

  return {
    ...userProfile,
    admin: true,
  };
};

export const getReidentificationToken = () => reidentificationTokenBody;

export const validateReidentificationToken = () => localStorage.getItem(reidentificationTokenKey)
  === reidentificationTokenBody.token;
