import * as Sentry from '@sentry/react';
import { Alert, Button, Icon } from '@whoop/web-components';
import {
  getPrivacyLevelColumn,
  getRoleColumn,
} from 'accountDetails/addCollaborators/addCollaboratorSteps/reviewCollaboratorDetails/reviewCollaboratorDetailsTable';
import { privacyLevelOptions } from 'accountDetails/types';
import CollaboratorsTable from 'accountDetails/collaboratorsTable/collaboratorsTable';
import { updateInvitedCollaborator } from 'api/adminApi';
import { getAllCollaborators, updateDashboardUserRoles } from 'api/dashboardApi';
import FeatureFlag from 'components/featureFlag';
import SearchBar from 'components/searchBar/searchBar';
import Tabs from 'components/tabs/tabs';
import { HEADING_2 } from 'components/text';
import { useAccounts } from 'context/account-context';
import { useAuth } from 'context/auth-context';
import { usePrivacy } from 'context/privacy-context';
import { trackEvent } from 'helpers/analyticsTracking';
import {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  CellProps,
  Column, Row, TableCommonProps,
} from 'react-table';
import { editDisplayRoleCell } from 'tableUtils/tableCells';
import {
  collaboratorNameColumn,
  COLLABORATORS_TABLE_SELECTION_COLUMN,
} from 'tableUtils/tableColumns';
import { Collaborator, InviteStatus, RemoveCollaboratorType } from 'types/collaborator';
import { DashboardRole } from 'types/dashboardUser';
import { UseStatePropType } from 'types/useStatePropType';
import styles from './collaboratorsPage.module.scss';
import MoreActionsCollaborators from './moreActionsCollaborator/moreActionsCollaborator';
import { mapDashboardRoleToWhoopRole } from './roleDropdown/util';

type CollaboratorsPageProps = {
  setSelectedRows: UseStatePropType<Row<Collaborator>[]>;
  selectedRows: Row<Collaborator>[];
  setRemovalModal: (
    type: RemoveCollaboratorType,
    removalModal: boolean
  ) => void;
  setResendInviteModal: UseStatePropType<boolean>;
};

function CollaboratorsPage({
  setSelectedRows,
  selectedRows,
  setRemovalModal,
  setResendInviteModal,
}: CollaboratorsPageProps) {
  const { checkAccess } = useAccounts();
  const { accountId } = useParams<{ accountId: string }>();
  const { user } = useAuth();
  const { updatePrivacyLevel } = usePrivacy();
  const navigate = useNavigate();

  const [search, setSearch] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState<InviteStatus>(InviteStatus.accepted);
  const [loadingCollaborators, setLoadingCollaborators] = useState<boolean>(true);
  const [collaborators, setCollaborators] = useState<Collaborator[]>([]);
  const [collaboratorsToDisplay, setCollaboratorsToDisplay] = useState<Collaborator[]>([]);

  const [numActive, setNumActive] = useState(0);
  const [numInvited, setNumInvited] = useState(0);
  const [numBounced, setNumBounced] = useState(0);

  const [inviteStatusList, setInviteStatusList] = useState<InviteStatus[]>([InviteStatus.accepted,
    InviteStatus.invited]);
  const roleOrder = Object.values(DashboardRole);

  const sortList = (list: Collaborator[]) => list.sort(
    (a, b) => roleOrder.indexOf(a.role) - roleOrder.indexOf(b.role),
  );

  const fetchCollaboratorData = useCallback(async () => {
    setLoadingCollaborators(true);
    try {
      setSearch('');
      const data: Collaborator[] = await getAllCollaborators();
      setCollaborators(data);
      setNumActive(data.filter((c) => c.status === InviteStatus.accepted).length);
      setNumInvited(data.filter((c) => c.status === InviteStatus.invited).length);
      const numBouncedFound = data.filter((c) => c.status === InviteStatus.bounced).length;
      setNumBounced(numBouncedFound);

      if (numBouncedFound > 0) {
        setInviteStatusList(inviteStatusList.concat(InviteStatus.bounced));
      }

      // Using this state variable so we don't reload collaborators every time the user switched
      // between tabs. This is meant to make the page faster.
      setCollaboratorsToDisplay(sortList(data.filter((c) => c.status === InviteStatus.accepted)));
    } catch (err) {
      Sentry.captureException(err);
      setCollaborators([]);
      setCollaboratorsToDisplay([]);
    }
    setLoadingCollaborators(false);
  }, [accountId]);

  useEffect(() => {
    fetchCollaboratorData();
  }, []);

  useEffect(() => {
    setCollaboratorsToDisplay(sortList(collaborators.filter(
      (c) => c.status === selectedTab,
    )));
  }, [selectedTab]);

  const displayTabNumber = (tab: InviteStatus) => {
    if (tab === InviteStatus.accepted) { return numActive; }
    if (tab === InviteStatus.invited) { return numInvited; }
    return numBounced;
  };

  const getSelectedRowClassname = (row: Row<Collaborator>): TableCommonProps => ({
    className:
        row.isSelected ? styles.selectedRow : '',
  });

  const handleUserUpdate = async (updatedRow: Collaborator) => {
    // Have to find original row by email because invited collabs dont have userId
    const originalCollaborator = collaborators.find(
      (u) => u.email === updatedRow.email,
    );

    const isActiveCollaborator = originalCollaborator.status === InviteStatus.accepted;

    if (isActiveCollaborator) {
      await updateDashboardUserRoles(
        updatedRow,
        accountId,
        mapDashboardRoleToWhoopRole(updatedRow.role),
        updatedRow.role === DashboardRole.ACCOUNT_ADMIN,
      );

      if (updatedRow.user_id === user.id) {
        await updatePrivacyLevel();
      }
    } else {
      await updateInvitedCollaborator(updatedRow);
    }

    if (updatedRow.user_id === user.id) {
      await updatePrivacyLevel();
    }

    const updateFunction = (s: Collaborator[]) => s.map((o) => (o === originalCollaborator
      ? updatedRow : o));

    setCollaborators((s) => sortList(updateFunction(s)));
    setCollaboratorsToDisplay((s) => sortList(updateFunction(s)));
  };

  const headerButtons = (
    checkAccess(DashboardRole.ACCOUNT_MANAGER) && (
    <div className={styles.tableControlButtons}>
      {selectedRows.length === 0 && (
      <>
        <FeatureFlag flagName="enterprise-manage-csms-fe">
          {checkAccess(DashboardRole.WHOOP_CSM) && (
          <Button
            size="medium"
            onClick={() => {
              navigate(`/accounts/${accountId}/plan/manage-csms`);
            }}
            theme="enterprise"
            variant="normal"
            className={styles.button}
          >
            <Icon name="community" />
            Manage WHOOP CSM
          </Button>
          )}
        </FeatureFlag>
        <Button
          label="ADD COLLABORATORS"
          size="medium"
          onClick={() => {
            trackEvent('Add Collaborators - Button Click', {
              accountId,
            });
            navigate(`/accounts/${accountId}/add-collaborators`);
          }}
          theme="enterprise"
          variant="normal"
          className={styles.button}
        >
          <Icon name="add" />
          ADD COLLABORATORS
        </Button>
      </>
      )}
      {selectedRows.length > 0 && (
      <>
        {selectedRows.map((c) => c.original.status).includes(InviteStatus.invited) && (
        <Button
          size="medium"
          onClick={() => setResendInviteModal(true)}
          theme="enterprise"
          variant="normal"
          className={styles.blueBtn}
        >
          <Icon name="send" />
          {' '}
          {`RESEND INVITE${selectedRows.length === 1 ? '' : 'S'}`}
        </Button>
        )}
        <Button
          size="medium"
          onClick={() => {
            setRemovalModal(RemoveCollaboratorType.ACCOUNT, true);
          }}
          theme="enterprise"
          variant="normal"
          className={selectedRows.map((c) => c.original.status)
            .includes(InviteStatus.invited) ? styles.button : styles.blueBtn}
        >
          <Icon name="trash" />
          {`DELETE COLLABORATOR${selectedRows.length === 1 ? '' : 'S'}`}
        </Button>
      </>
      )}
    </div>
    )
  );

  const moreActionsCell = (cellProps: CellProps<Collaborator>) => (
    <MoreActionsCollaborators
      row={cellProps.row}
      setSelectedRows={setSelectedRows}
      setRemovalModal={setRemovalModal}
      setResendInviteModal={setResendInviteModal}
    />
  );

  const columnList: Column<Collaborator>[] = useMemo(() => [
    COLLABORATORS_TABLE_SELECTION_COLUMN(),
    collaboratorNameColumn,
    editDisplayRoleCell(!checkAccess(DashboardRole.ACCOUNT_MANAGER), checkAccess),
    getRoleColumn('role', !checkAccess(DashboardRole.ACCOUNT_MANAGER), checkAccess, user) as Column<Collaborator>,
    getPrivacyLevelColumn('privacy_level', privacyLevelOptions, !checkAccess(DashboardRole.ACCOUNT_MANAGER), checkAccess) as Column<Collaborator>,
    {
      id: 'more_actions',
      Cell: moreActionsCell,
    },
  ], [privacyLevelOptions]);

  const headingRef = useRef(null);
  const executeScrollToHeading = () => headingRef.current.scrollIntoView({ behavior: 'smooth' });

  const tabList = inviteStatusList.map((status) => ({ tab: status, displayTitle: status === InviteStatus.bounced ? 'invite failed' : status, badgeContent: displayTabNumber(status).toString() }));

  return (
    <div>
      <div className={styles.content} ref={headingRef}>
        <HEADING_2>Permissions</HEADING_2>
        <p>View and manage collaborators on this account.</p>
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.stickyHeader}>
          <div className={styles.tableControlBar}>
            <div className={styles.searchBar}>
              <SearchBar
                placeholder="Search by name or email"
                setSearchTerm={setSearch}
                value={search}
                setValue={setSearch}
                hasBorder
              />
            </div>
            {headerButtons}
          </div>
          <div className={styles.tabContainer}>
            <Tabs
              tabList={tabList}
              selectedTab={selectedTab}
              onClick={(tab) => setSelectedTab(tab)}
            />
          </div>
        </div>
        {selectedTab === InviteStatus.bounced && (
        <div className={styles.error}>
          <Alert theme="default" type="error">
            Invitation(s) could not be delivered.
            Copy each collaborator’s unique invite link and share it with them directly.
          </Alert>
        </div>
        )}
        <CollaboratorsTable
          columns={columnList}
          data={collaboratorsToDisplay}
          loading={loadingCollaborators}
          setSelectedRows={setSelectedRows}
          updateRow={handleUserUpdate}
          keywordFilter={search}
          resetPage={selectedTab}
          getAdditionalRowProps={getSelectedRowClassname}
          scrollToTop={executeScrollToHeading}
        />
      </div>
    </div>
  );
}

export default CollaboratorsPage;
