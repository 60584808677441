import {
  Icon, classes, Tooltip, TooltipThemes,
  TooltipDirection,
} from '@whoop/web-components';
import styles from './iconTooltip.module.scss';
import { MoreInfoText } from './iconTooltipTypes';

type IconTooltipProps = {
  icon: string;
  moreInfoText: MoreInfoText;
};

function IconTooltip({
  moreInfoText,
  icon,
}: IconTooltipProps) {
  const {
    header,
    details,
  } = moreInfoText;

  return (
    <Tooltip
      className={styles.infoTooltip}
      content={(
        <div className={classes('whoop-typography', styles.tooltipContent)}>
          {header
              && (
              <div className={classes(styles.headerText, 'c2')}>
                {header}
              </div>
              )}
          <div className={classes(styles.detailsText, 'c2')}>
            {details}
          </div>
        </div>
          )}
      verticalOffset={moreInfoText.verticalOffset}
      horizontalOffset={moreInfoText.horizontalOffset}
      direction={moreInfoText.direction ?? TooltipDirection.BOTTOM}
      delay={0}
      theme={TooltipThemes.UNITE}
    >
      <Icon className={styles.infoCompSize} name={icon} />
    </Tooltip>
  );
}

export default IconTooltip;
