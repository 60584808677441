import { Colors } from '@whoop/web-components';
import { Pillars } from 'types/analytics';

export type PillarBreakdownDataWithMetric = PillarBreakdownBarData & {
  metric?: string;
  allOutDays?: string[];
  strenuousDays?: string[];
  moderateDays?: string[];
  lightDays?: string[];
  greenDays?: string[];
  yellowDays?: string[];
  redDays?: string[];
};

export type PillarBreakdownBarData = {
  [key in StrainKeys | RecoveryKeys | SleepKeys]?: number;
};

export type PillarBreakdownDayData = {
  [key in StrainKeys | RecoveryKeys | SleepKeys]?: string[];
};

export type PillarKeys = StrainKeys | RecoveryKeys | SleepKeys;

export enum StrainKeys {
  ALLOUT = 'allOut',
  STRENUOUS = 'strenuous',
  MODERATE = 'moderate',
  LIGHT = 'light',
}

export enum RecoveryKeys {
  GREEN = 'green',
  YELLOW = 'yellow',
  RED = 'red',
}

export enum SleepKeys {
  OPTIMAL = 'optimal',
  SUFFICIENT = 'sufficient',
  POOR = 'poor',
}

export const disabledColors = [Colors.gray500, '#D1D1D1', '#DADADA'];
export const strainColors = [Colors.strainBlue, '#31A7EA', '#62BAED', '#93CEEF'];
export const disabledStrainColors = [...disabledColors, '#E3E3E3'];
export const strainKeys = Object.values(StrainKeys);
export const recoveryColors = [Colors.green, '#F4BF00', Colors.brandingRed];
export const recoveryKeys = Object.values(RecoveryKeys);
export const sleepColors = [Colors.sleepBlue, '#93B2C7', '#ACC3D2'];
export const sleepKeys = Object.values(SleepKeys);

export const PILLAR_KEY_MAP = {
  [Pillars.STRAIN]: strainKeys,
  [Pillars.RECOVERY]: recoveryKeys,
  [Pillars.SLEEP]: sleepKeys,
};

export type PillarBarDetails = {
  name: string;
  description: string;
  color: string;
  colorIndex?: number;
};

export const pillarKeysToDisplay = {
  [StrainKeys.ALLOUT]: {
    name: 'All Out',
    description: '18.0+',
    color: strainColors[0],
    disabledColor: disabledStrainColors[0],
    colorIndex: 0,
  },
  [StrainKeys.STRENUOUS]: {
    name: 'Strenuous',
    description: '14.1 - 18.0',
    color: strainColors[1],
    disabledColor: disabledStrainColors[1],
    colorIndex: 1,
  },
  [StrainKeys.MODERATE]: {
    name: 'Moderate',
    description: '10.1 - 14.0',
    color: strainColors[2],
    disabledColor: disabledStrainColors[2],
    colorIndex: 2,
  },
  [StrainKeys.LIGHT]: {
    name: 'Light',
    description: '<10.0',
    color: strainColors[3],
    disabledColor: disabledStrainColors[3],
    colorIndex: 3,
  },
  [RecoveryKeys.GREEN]: {
    name: 'Green',
    description: '67%+',
    color: recoveryColors[0],
    disabledColor: disabledColors[0],
    colorIndex: 0,
  },
  [RecoveryKeys.YELLOW]: {
    name: 'Yellow',
    description: '30% - 67%',
    color: recoveryColors[1],
    disabledColor: disabledColors[1],
    colorIndex: 1,
  },
  [RecoveryKeys.RED]: {
    name: 'Red',
    description: '< 30%',
    color: recoveryColors[2],
    disabledColor: disabledColors[2],
    colorIndex: 2,
  },
  [SleepKeys.OPTIMAL]: {
    name: 'Optimal',
    description: '85%+',
    color: sleepColors[0],
    disabledColor: disabledColors[0],
  },
  [SleepKeys.SUFFICIENT]: {
    name: 'Sufficient',
    description: '70% - 85%',
    color: sleepColors[1],
    disabledColor: disabledColors[1],
  },
  [SleepKeys.POOR]: {
    name: 'Poor',
    description: '< 70%',
    color: sleepColors[2],
    disabledColor: disabledColors[2],
  },
};
