import AccountPage from 'account/account';
import Routes from 'components/routes';
import Loading from 'loading';
import { Navigate, Route } from 'react-router-dom';
import { AccountProvider, useAccounts } from 'context/account-context';
import AccountDetails from 'accountDetails/accountDetails';
import AddGroupWizard from 'groups/addGroupWizard/addGroupWizard';
import AddCollaborators from 'accountDetails/addCollaborators/addCollaborators';
import ViewGroupsPage from 'groups/viewGroupsPage';
import ProgressPage from 'progress/progressPage';
import Collaborator from 'accountDetails/collaborator/collaborator';
import ProgressPageTabs from 'types/progressPage';
import ManageCSMs from 'accountDetails/manageCSMs/manageCSMs';
import NavigationBar from 'components/navigationBar/navigationBar';
import Footer from 'components/footer/footer';
import HubRoutes from 'hubRoutes';
import AddCollaboratorsPage from 'groups/addCollaboratorsPage/addCollaboratorsPage';
import AddGroupMembersPage from 'groups/addGroupMembersPage';
import RemoveGroup from 'groups/removeGroup/removeGroup';
import Profile from 'progress/profile/profile';
import PillarRangeSelect from 'progress/profile/pillarRangeSelect';
import NotificationBanner from 'components/notificationBanner/notificationBanner';
import ErrorPage from 'messagePage/errorPage/errorPage';
import { useEffect, useState } from 'react';
import GroupsHeader from 'insights/components/groupsHeader/groupsHeader';
import InsightsTabSelect from 'insights/insightsTabSelect';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import ResetAccount from './components/resetAccount/resetAccount';
import styles from './accountRoutes.module.scss';

function AccountRoutes() {
  const { loading } = useAccounts();
  const { getFeatureFlag } = useFeatureFlags();
  const [showTeamsPage, setShowTeamsPage] = useState(false);

  if (loading) {
    return <Loading />;
  }

  const getShowTeamsPage = async () => {
    const data = await getFeatureFlag('unite-team-level-dashboard');
    setShowTeamsPage(data);
  };

  useEffect(() => {
    getShowTeamsPage();
  }, []);

  return (
    <div className={styles.pageContainer}>
      {window._env.SHOW_BANNER === 'true' && <NotificationBanner />}
      <div className={styles.contentWrap}>
        <AccountProvider>
          <NavigationBar />
          <Routes>
            <Route index element={<Navigate to="people/members" replace />} />
            {showTeamsPage && (
            <Route path="insights">
              <Route path="profile/:userId">
                <Route element={<Profile />}>
                  <Route index element={<Navigate to="overview" replace />} />
                  <Route path=":pillar" element={<PillarRangeSelect />} />
                </Route>
              </Route>
              <Route element={<GroupsHeader />}>
                <Route index element={<Navigate to="groups" replace />} />
                <Route path=":tab" element={<InsightsTabSelect />} />
                <Route path=":tab/:groupId" element={<InsightsTabSelect />} />
              </Route>
            </Route>
            )}
            <Route path="progress">
              <Route index element={<ProgressPage tabSelected={ProgressPageTabs.SUMMARY} />} />
              <Route path="breakdown" element={<ProgressPage tabSelected={ProgressPageTabs.BREAKDOWN} />} />
              <Route path=":userId">
                <Route index element={<Navigate to="profile" replace />} />
                <Route path="profile" element={<Profile />}>
                  <Route index element={<Navigate to="overview" replace />} />
                  <Route path=":pillar" element={<PillarRangeSelect />} />
                </Route>
              </Route>
            </Route>
            <Route path="people">
              <Route index element={<Navigate to="members" replace />} />
              <Route path="members" element={<AccountPage />} />
              <Route path="hub/*" element={<HubRoutes />} />
              <Route path="groups">
                <Route index element={<ViewGroupsPage />} />
                <Route path="add-group" element={<AddGroupWizard />} />
                <Route path=":groupId" element={<ViewGroupsPage />} />
                <Route path=":groupId/delete" element={<RemoveGroup />} />
                <Route path=":groupId/add-collaborators" element={<AddCollaboratorsPage />} />
                <Route path=":groupId/add-members" element={<AddGroupMembersPage />} />
              </Route>
            </Route>
            <Route path="plan">
              <Route path="settings/reset-account" element={<ResetAccount />} />
              <Route path="permissions/:userId" element={<Collaborator />} />
              <Route path="manage-csms" element={<ManageCSMs />} />
              <Route path="*" element={<AccountDetails />} />
            </Route>
            <Route path="add-collaborators" element={<AddCollaborators />} />
            <Route path="*" element={<ErrorPage includeFooter={false} />} />
          </Routes>
        </AccountProvider>
      </div>
      <Footer logo={false} />
    </div>
  );
}

export default AccountRoutes;
