import {
  sleepDebtBaseColumns, sleepPerformanceBaseColumns, strainBaseColumns,
  recoveryBaseColumns, strainExpandedColumns, sleepPerformanceExpandedColumns,
  sleepDebtExpandedColumns, recoveryExpandedColumns,
} from 'insights/components/leaderboardTable/useLeaderboardTable';
import { PieChartLabels } from 'dataVisualizations/iconPieChart/iconPieChartConsts';
import { WidgetType } from 'insights/components/widget/widget';

export const WIDGET_TYPE_TO_NAME = {
  [WidgetType.DAY_STRAIN]: 'Day Strain',
  [WidgetType.SLEEP_PERFORMANCE]: 'Sleep Performance',
  [WidgetType.SLEEP_DEBT]: 'Sleep Debt',
  [WidgetType.RECOVERY]: 'Recovery',
};

export const BREAKDOWN_LABEL_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: PieChartLabels.STRAIN,
  [WidgetType.SLEEP_PERFORMANCE]: PieChartLabels.SLEEP_PERFORMANCE,
  [WidgetType.SLEEP_DEBT]: PieChartLabels.SLEEP_DEBT,
  [WidgetType.RECOVERY]: PieChartLabels.RECOVERY,
};

export const COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: strainBaseColumns,
  [WidgetType.SLEEP_PERFORMANCE]: sleepPerformanceBaseColumns,
  [WidgetType.SLEEP_DEBT]: sleepDebtBaseColumns,
  [WidgetType.RECOVERY]: recoveryBaseColumns,
};

export const EXPANDED_LEADERBOARD_COLUMNS_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: strainExpandedColumns,
  [WidgetType.SLEEP_PERFORMANCE]: sleepPerformanceExpandedColumns,
  [WidgetType.SLEEP_DEBT]: sleepDebtExpandedColumns,
  [WidgetType.RECOVERY]: recoveryExpandedColumns,
};

export const ICON_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: 'strain',
  [WidgetType.SLEEP_PERFORMANCE]: 'sleep',
  [WidgetType.SLEEP_DEBT]: 'sleep',
  [WidgetType.RECOVERY]: 'recovery_score',
};

export const DEFAULT_SORT_BY_WIDGET = {
  [WidgetType.DAY_STRAIN]: 'strain',
  [WidgetType.SLEEP_PERFORMANCE]: 'sleep_performance',
  [WidgetType.SLEEP_DEBT]: 'sleep_debt',
  [WidgetType.RECOVERY]: 'recovery',
};
