import { RecoveryLeaderboard, SleepLeaderboard, StrainLeaderboard } from 'api/widgetApi';
import { useMemo } from 'react';
import {
  CellProps,
  Column, Row, useSortBy, useTable,
} from 'react-table';
import { widgetNameAvatarCell } from 'tableUtils/tableCells';
import {
  minutesToHoursCell, recoveryCell, roundedNumberCell, strainCell,
} from './leaderboardTableCells';
import { WidgetType } from '../widget/widget';

const nameColumn = {
  Header: 'Leaderboard', accessor: 'name', disableSortBy: true, Cell: widgetNameAvatarCell,
};

export const recoveryBaseColumns = [nameColumn,
  {
    Header: 'Recovery',
    accessor: 'recovery',
    sortType: 'alphanumeric',
    Cell: (cellProps: CellProps<RecoveryLeaderboard>) => recoveryCell(cellProps.value),
  },
];

export const recoveryExpandedColumns = [...recoveryBaseColumns,
  {
    Header: 'RHR',
    accessor: 'rhr',
    sortType: 'alphanumeric',
    Cell: (cellProps: CellProps<
    RecoveryLeaderboard
    >) => roundedNumberCell(cellProps.value, WidgetType.RECOVERY),
  },
  {
    Header: 'HRV',
    accessor: 'hrv',
    sortType: 'alphanumeric',
    Cell: (cellProps: CellProps<
    RecoveryLeaderboard
    >) => roundedNumberCell(cellProps.value, WidgetType.RECOVERY),
  },
];

const sleepPerformanceCell = {
  Header: 'Performance',
  accessor: 'sleep_performance',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<
  RecoveryLeaderboard
  >) => roundedNumberCell(cellProps.value, WidgetType.SLEEP_PERFORMANCE, true),
};

const hoursOfSleepCell = {
  Header: 'Hours of Sleep',
  accessor: 'sleep_duration_minutes',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<SleepLeaderboard>) => minutesToHoursCell(cellProps.value),
};

const sleepDebtCell = {
  Header: 'Sleep Debt',
  accessor: 'sleep_debt_minutes',
  sortType: 'alphanumeric',
  Cell: (cellProps: CellProps<SleepLeaderboard>) => minutesToHoursCell(cellProps.value),
};

export const sleepPerformanceBaseColumns = [nameColumn, sleepPerformanceCell];

export const sleepPerformanceExpandedColumns = [...sleepPerformanceBaseColumns,
  hoursOfSleepCell,
  sleepDebtCell,
];

export const sleepDebtBaseColumns = [nameColumn, sleepDebtCell];

export const sleepDebtExpandedColumns = [
  ...sleepDebtBaseColumns,
  hoursOfSleepCell,
  sleepPerformanceCell,
];

// Needed this because in one case the values were slightly out of order for strain
// http://localhost:3000/accounts/0013B00000iNx7AQAS/insights/groups/218088?from=2023-04-26&to=2023-05-25
const compareStrain = (
  rowA: Row<StrainLeaderboard>,
  rowB: Row<StrainLeaderboard>,
  id: string,
) => {
  if (rowA.values[id] > rowB.values[id]) return 1;
  if (rowB.values[id] > rowA.values[id]) return -1;

  return 0;
};

export const strainBaseColumns = [nameColumn,
  {
    Header: 'Strain',
    accessor: 'strain',
    sortType: compareStrain,
    Cell: (cellProps: CellProps<StrainLeaderboard>) => strainCell(cellProps.value),
  }];

export const strainExpandedColumns = [...strainBaseColumns,
  {
    Header: 'avg HR',
    accessor: 'hr',
    sortType: 'alphanumeric',
    Cell: (cellProps: CellProps<
    StrainLeaderboard
    >) => roundedNumberCell(cellProps.value, WidgetType.DAY_STRAIN),
  },
  {
    Header: 'Calories',
    accessor: 'calories',
    sortType: 'alphanumeric',
    Cell: (cellProps: CellProps<
    StrainLeaderboard
    >) => roundedNumberCell(cellProps.value, WidgetType.DAY_STRAIN),
  },
];

export type LeaderboardTableRow = {
  user_id: number;
};

function useLeaderboardTable(
  data: RecoveryLeaderboard[] | StrainLeaderboard[] | SleepLeaderboard[],
  columns: Column[],
  defaultSortById: string,
) {
  // Memoizing as react-table suggests this
  const columnsToUse = useMemo(
    () => columns as Column<LeaderboardTableRow>[],
    [columns],
  );

  return {
    ...useTable(
      {
        columns: columnsToUse,
        data,
        disableSortRemove: true,
        initialState: {
          sortBy: [
            {
              id: defaultSortById,
              desc: true,
            },
          ],
        },
      },
      useSortBy,
    ),
  };
}

export default useLeaderboardTable;
