import { useParams } from 'react-router-dom';
import GroupsPage from './groupsPage/groupsPage';
import IndividualsPage from './individualsPage/individualsPage';
import GroupDetailsView from './groupDetailsView/groupDetailsView';
import { InsightsParams, GroupsPageTabs } from './components/groupsHeader/groupsHeader';

function InsightsTabSelect() {
  const { tab, groupId } = useParams<InsightsParams>();

  if (tab === GroupsPageTabs.INDIVIDUALS) {
    return <IndividualsPage />;
  }
  if (groupId) {
    return <GroupDetailsView />;
  }
  return <GroupsPage />;
}

export default InsightsTabSelect;
