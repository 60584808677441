import { Col, Row } from '@whoop/web-components';
import { WidgetType } from 'insights/components/widget/widget';
import KeyStatisticWidget from 'insights/components/widget/keyStatisticWidget/keyStatisticWidget';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import styles from './groupDetailsView.module.scss';

export const widgetsToDisplay = [WidgetType.RECOVERY,
  WidgetType.SLEEP_PERFORMANCE, WidgetType.DAY_STRAIN];

function GroupDetailsView() {
  const [expandedCards, setExpandedCards] = useState([]);
  const { accountId } = useParams<{ groupId: string;
    accountId: string; }>();
  const navigate = useNavigate();
  const { isInPrivacyLevel } = usePrivacy();

  useEffect(() => {
    if (!isInPrivacyLevel(PrivacyLevel.aggregate_metrics)) {
      navigate(`/accounts/${accountId}/insights/groups`);
    }
  }, []);

  const isExpanded = (type: WidgetType) => expandedCards.includes(type);

  const toggleExpanded = (type: WidgetType) => {
    if (expandedCards.includes(type)) {
      setExpandedCards(expandedCards.filter((item) => item !== type));
    } else {
      setExpandedCards((oldArray) => [...oldArray, type]);
    }
  };

  const sizeOf = (type: WidgetType) => (isExpanded(type) ? 12 : 4);
  const positionOf = (type: WidgetType) => (isExpanded(type) ? 'xs' : '');

  return (
    <Row className={styles.groupDetailsViewContainer}>
      {Object.values(widgetsToDisplay).map((widgetType) => (
        <Col
          key={widgetType}
          first={positionOf(widgetType)}
          xs={sizeOf(widgetType)}
          className={styles.transition}
        >
          <KeyStatisticWidget
            widgetType={widgetType}
            isExpanded={isExpanded(widgetType)}
            toggleExpanded={toggleExpanded}
          />
        </Col>

      ))}
    </Row>
  );
}

export default GroupDetailsView;
