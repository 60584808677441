import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useLocation, useSearchParams } from 'react-router-dom';
import { URLDateRangeParams } from 'types/profile';
import { formatDate, isValidDateRange } from '../profileUtils';

// Manage DateRange and SearchParams in one place
// the dateRange is driven by the searchParams in the URL
// in order to change the dateRange one should use the applySearchParam method
export const useDateRange = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const currentDayDateRange = { from: dayjs().startOf('day').toDate() };

  // build dateRange object from search params
  // if there are no search params return current day dateRange
  const getRangeFromSearchParams = () => {
    let dateRangeFromSearchParams: DateRange = currentDayDateRange;
    if (searchParams.get(URLDateRangeParams.from)) {
      dateRangeFromSearchParams = {
        from: dayjs(searchParams.get(URLDateRangeParams.from)).toDate(),
        to: searchParams.get(URLDateRangeParams.to)
          ? dayjs(searchParams.get(URLDateRangeParams.to)).toDate()
          : null,
      };
    }

    return dateRangeFromSearchParams;
  };

  // apply the given dateRange to the search params
  const setDateRange = (range: DateRange, replace: boolean = false) => {
    let rangeToBeSet = range;
    if (!isValidDateRange(rangeToBeSet)) {
      rangeToBeSet = currentDayDateRange;
    }

    const params = new URLSearchParams(searchParams);
    // if the dateRange is changing we want to remove the activityId
    // since it's only associated with a singluar day
    params.delete('activityId');
    params.set('from', formatDate(rangeToBeSet.from));
    if (rangeToBeSet.to) {
      params.set('to', formatDate(rangeToBeSet.to));
    } else {
      params.delete('to');
    }

    setSearchParams(params, { replace });
  };

  // creates the dateRange from the search params in the URL
  const dateRange = useMemo(() => {
    let dateRangeFromSearchParams: DateRange = getRangeFromSearchParams();
    if (isValidDateRange(dateRangeFromSearchParams)) {
      return dateRangeFromSearchParams;
    }
    dateRangeFromSearchParams = currentDayDateRange;
    return dateRangeFromSearchParams;
  }, [location?.search]);

  const comparisonDateRange = useMemo(() => {
    const previousRange: DateRange = {
      from: dayjs(dateRange.from).subtract(1, 'day').toDate(),
    };

    if (dateRange.to) {
      const daysBetween = dayjs(dateRange.to).diff(dayjs(dateRange.from), 'days');
      previousRange.to = dayjs(dateRange.from).toDate();
      previousRange.from = dayjs(dateRange.from).subtract(daysBetween, 'day').toDate();
      return previousRange;
    }

    return previousRange;
  }, [dateRange]);

  useEffect(() => {
    if (!searchParams.get(URLDateRangeParams.from)) {
      setDateRange(currentDayDateRange, true);
    }
  }, []);

  return { dateRange, setDateRange, comparisonDateRange };
};
