import { AccountConfigurationSettingType, AccountDetailsTab } from 'accountDetails/types';
import { adminClient } from 'api/';
import DemoDisabled from 'components/demoDisabled';
import FeatureFlag from 'components/featureFlag';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AccountConfiguration, reidentificationTokenKey } from 'types/admin';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import PrivacyLevelTable from 'accountDetails/privacyLevelTable/privacyLevelTable';
import CustomSwitch from 'accountDetails/settingsV2/customSwitch';
import { classes, Icon } from '@whoop/web-components';
import JobTitleSettings from 'components/jobTitleSetting/jobTitleSettings';
import {
  createAccountJobTitle, deleteAccountJobTitle,
  getAccountJobTitles, JobTitle, updateAccountJobTitle,
} from 'api/jobTitleApi';
import styles from './settingsV2.module.scss';
import SettingCard from './settingCard';

type AccountDetailsParams = {
  accountId: string;
  tabTitle: AccountDetailsTab;
};

function SettingsV2() {
  const {
    checkAccess, setAccountConfig, accountConfig, isReidTokenValid, loading,
  } = useAccounts();
  const { updatePrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const { accountId } = useParams<AccountDetailsParams>();
  const [isReidentified, setIsReidentified] = useState<boolean>(false);
  // The job titles that are getting used on this account
  const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
  // Initial job title options available to the account
  const [initialJobTitleOptions, setInitialJobTitleOptions] = useState<JobTitle[]>([]);

  useEffect(() => {
    const init = async () => {
      if (accountConfig.deidentified) {
        const tokenState = await isReidTokenValid();
        setIsReidentified(tokenState);
      }
      const allJobTitlesData = await getAccountJobTitles();
      setInitialJobTitleOptions(allJobTitlesData);
      // Job titles that have a privacy level are ones that have been set
      const usedJobTitles = allJobTitlesData.filter((jobTitle: JobTitle) => jobTitle.privacy_level);
      setJobTitles(usedJobTitles);
    };

    if (!checkAccess(DashboardRole.WHOOP_CSM)) {
      navigate(`/accounts/${accountId}/plan/summary`);
    }

    init();
  }, [accountId]);

  // Gets the array of strings of job title names that are not yet used on the account
  const jobTitleOptionsToDisplay = useMemo(() => {
    const newJobTitleOptions = initialJobTitleOptions.filter(
      (option: JobTitle) => !jobTitles.some(
        (jobTitle: JobTitle) => jobTitle.job_title_name === option.job_title_name,
      ),
    );
    return newJobTitleOptions;
  }, [jobTitles, initialJobTitleOptions]);

  const updateSettings = async (body: AccountConfiguration): Promise<void> => {
    const updatedConfig: AccountConfiguration = await adminClient.updateAccountConfiguration(body);
    setAccountConfig(updatedConfig);
    updatePrivacyLevel();
  };

  const handleToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const toggleToChange = e.target.name;
    const newValue = e.target.value === 'false';
    const body: AccountConfiguration = {
      ...accountConfig,
      [toggleToChange]: newValue,
    };
    updateSettings(body);
  };

  const handlePrivacyLevelChange = (value: PrivacyLevel) => {
    updateSettings({
      ...accountConfig,
      privacy_ceiling_level: value,
    });
  };

  const handleReidentificationToggle = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === 'false';
    if (newValue) {
      const reidentificationTokenObject = await adminClient.getReidentificationToken();
      const { token } = reidentificationTokenObject;
      localStorage.setItem(reidentificationTokenKey, token);
    } else {
      localStorage.removeItem(reidentificationTokenKey);
    }
    setIsReidentified(newValue);
  };

  const handleResetAccount = () => {
    navigate('reset-account');
  };

  const updateJobTitleObject = async (jobTitle: JobTitle, updatedJobTitle: JobTitle) => {
    const newId = initialJobTitleOptions.find(
      (option: JobTitle) => option.job_title_name === updatedJobTitle.job_title_name,
    ).job_title_id;
    await updateAccountJobTitle(
      newId,
      jobTitle.job_title_id,
      updatedJobTitle.privacy_level,
      accountId,
    );
    const newJobTitles: JobTitle[] = [...jobTitles];
    const foundIndex = newJobTitles.findIndex(
      (jobTitleOption: JobTitle) => jobTitleOption.job_title_id === jobTitle.job_title_id,
    );
    // Replace old job title object with updated one
    newJobTitles[foundIndex] = { ...updatedJobTitle, job_title_id: newId };

    setJobTitles(newJobTitles);
  };

  const deleteJobTitle = async (jobTitle: JobTitle) => {
    await deleteAccountJobTitle(jobTitle.job_title_id, accountId);
    const newJobTitles: JobTitle[] = [...jobTitles];
    const foundIndex = newJobTitles.findIndex(
      (newJobTitle: JobTitle) => jobTitle.job_title_id === newJobTitle.job_title_id,
    );
    // Remove job title from the list displayed
    newJobTitles.splice(foundIndex, 1);
    setJobTitles(newJobTitles);
  };

  const addJobTitle = async () => {
    // Adds the first unused job title on the account, as one that can be attached to collaborators
    // This defaults to no_metrics. You can then update the job name or privacy level
    const firstUnusedJobTitle = jobTitleOptionsToDisplay[0];
    const newJobTitle = await createAccountJobTitle(firstUnusedJobTitle.job_title_id, accountId);
    setJobTitles([...jobTitles,
      { ...newJobTitle, job_title_name: firstUnusedJobTitle.job_title_name }]);
  };

  return (!loading
    && (
      <div className={styles.content}>
        <p className={styles.sectionTitle}>
          Member Privacy
        </p>
        <SettingCard
          title="Set Up account-wide data access"
          description="This is the maximum amount of data collaborators on this account can access"
          name="account-wide-data-access"
        >
          <PrivacyLevelTable
            privacyLevel={accountConfig.privacy_ceiling_level}
            changePrivacyLevel={handlePrivacyLevelChange}
          />
        </SettingCard>
        <SettingCard
          isEnabled={accountConfig.opt_out_enabled}
          onChangeHandler={handleToggle}
          title="Allow members to share data as group averages"
          description="By toggling this on, members on this account will have the option in the Join Flow to share their individual data with their organization, or share their data as a team average only."
          name="opt_out_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        <FeatureFlag flagName="enterprise-fe-deid-toggle">
          <SettingCard
            isEnabled={accountConfig.deidentified}
            onChangeHandler={handleToggle}
            title="Account Deidentification"
            description="Account wide deidentification for member PII."
            name="deidentified"
            type={AccountConfigurationSettingType.Toggle}
          />
          {accountConfig.deidentified
            && (
              <div className={styles.toggleContainer}>
                <p>Temporarily re-identify account members?</p>
                <div className={styles.buttonWrap}>
                  <CustomSwitch
                    onChange={handleReidentificationToggle}
                    value={isReidentified}
                    checked={isReidentified}
                    name="reidentification"
                    customheight={30}
                    customwidth={60}
                  />
                </div>
              </div>
            )}
        </FeatureFlag>
        <p className={classes(styles.sectionTitle, styles.sectionSpacing)}>
          Collaborator Setting
        </p>
        <SettingCard
          title="Job-based data access permission"
          name="job_titles"
        >
          {jobTitles.length === 0 && (
            <div className={classes(styles.addJobTitleContainer)}>
              Add a job title
              <Icon data-testid="addFirstJobTitle" name="add_circle" className={styles.icon} onClick={addJobTitle} />
            </div>
          )}
          <div className={styles.jobTitlesContainer}>
            {jobTitles.map((jobTitle: JobTitle, index: number) => (
              <JobTitleSettings
                key={jobTitle.job_title_id}
                jobTitle={jobTitle}
                updateJobTitle={updateJobTitleObject}
                // Can't delete only job title
                deleteEnabled={jobTitles.length > 1}
                // Only show add button on last row and if there are any job titles left to assign
                addEnabled={index === jobTitles.length - 1 && jobTitleOptionsToDisplay.length > 0}
                onDelete={deleteJobTitle}
                addJobTitle={addJobTitle}
                jobTitleOptions={jobTitleOptionsToDisplay.map(
                  (option: JobTitle) => option.job_title_name,
                )}
              />
            ))}
          </div>
        </SettingCard>

        <p className={classes(styles.sectionTitle, styles.sectionSpacing)}>
          Preference
        </p>
        <SettingCard
          isEnabled={accountConfig.strap_onboarding_enabled}
          onChangeHandler={handleToggle}
          title="Strap Based Onboarding Enabled"
          description="Members who onboard through strap based onboarding get added to the team associated with the strap ID"
          name="strap_onboarding_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        <FeatureFlag flagName="enterprise-fe-concealed-mode-toggle">
          <SettingCard
            isEnabled={accountConfig.concealed_feature_enabled}
            onChangeHandler={handleToggle}
            title="Conceal the Member&apos;s App"
            description={`${accountConfig.concealed_feature_enabled ? 'ON' : 'OFF'} 
            – Member can use the WHOOP app
            without intervention.`}
            name="concealed_feature_enabled"
            type={AccountConfigurationSettingType.Toggle}
          />
          {accountConfig.concealed_feature_enabled
            && (
              <div className={styles.toggleContainer}>
                <p>Auto-conceal all members</p>
                <div className={styles.buttonWrap}>
                  <CustomSwitch
                    onChange={handleToggle}
                    value={accountConfig.concealed}
                    checked={accountConfig.concealed}
                    name="concealed"
                    customheight={30}
                    customwidth={60}
                  />
                </div>
              </div>
            )}
        </FeatureFlag>
        <SettingCard
          isEnabled={accountConfig.hub_enabled}
          onChangeHandler={handleToggle}
          title="Bluetooth Hub Enabled"
          description="A Bluetooth Hub allows members without mobile devices to upload data from their straps."
          name="hub_enabled"
          type={AccountConfigurationSettingType.Toggle}
        />
        {
          accountConfig.hub_enabled && (
            <SettingCard
              isEnabled={accountConfig.bluetooth_roaming}
              onChangeHandler={handleToggle}
              title="Bluetooth Roaming Enabled"
              description="Bluetooth roaming allows all straps to connect to any hub in the account"
              name="bluetooth_roaming"
              type={AccountConfigurationSettingType.Toggle}
            />
          )
        }
        <DemoDisabled>
          <>
            <p className={classes(styles.sectionTitle, styles.sectionSpacing)}>
              Reset Account
            </p>
            <SettingCard
              onChangeHandler={handleResetAccount}
              title="Reset Account"
              description="Delete all members and groups."
              name="reset_account_button"
              type={AccountConfigurationSettingType.Button}
            />
          </>
        </DemoDisabled>
      </div>
    )
  );
}

export default SettingsV2;
