import { inviteSteps } from 'wizards/invite/constants';
import { useEffect, useReducer, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { BODY_SHORT_1, TITLE_3 } from 'components/text';
import ActionContent from 'components/actionContent/actionContent';
import Toast from 'components/toast';
import ProgressActionFooter from 'components/progressActionFooter/progressActionFooter';
import ActionFooter from 'components/actionFooter/actionFooter';
import AddCollaboratorEmails from 'accountDetails/addCollaborators/addCollaboratorSteps/addCollaboratorEmails/addCollaboratorEmails';
import ReviewCollaboratorDetails from 'accountDetails/addCollaborators/addCollaboratorSteps/reviewCollaboratorDetails/reviewCollaboratorDetails';
import { DashboardRole } from 'types/dashboardUser';
import { useAccounts } from 'context/account-context';
import { trackEvent } from 'helpers/analyticsTracking';
import ErrorPage from 'messagePage/errorPage/errorPage';
import {
  addCollaboratorsReducer,
  getSecondaryButtonProps,
  getPrimaryButtonProps,
  HandleSendInvitesPayload,
  handleSendInvites,
  createToastMessage,
} from './util';

function AddCollaborators() {
  const [state, dispatch] = useReducer(addCollaboratorsReducer, {
    inviteStep: inviteSteps.input,
    invitedCollaborators: [],
    emails: [],
    sentData: false,
    error: false,
  });
  const {
    inviteStep,
    invitedCollaborators,
    sentData,
    error,
  } = state;
  const [toastOpen, setToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState('');
  const { accountId } = useParams<{ accountId: string }>();
  const { checkAccess } = useAccounts();

  const [emails, setEmails] = useState<string[]>([]);
  const [invalidEmails, setInvalidEmails] = useState<string[]>([]);

  useEffect(() => {
    const sendInvites = async () => {
      trackEvent('Add Collaborators - Send Invites', {
        accountId,
      });
      const updatedCollabs = invitedCollaborators.map(
        (collab) => (
          { ...collab, is_primary_owner: collab.role === DashboardRole.ACCOUNT_ADMIN }
        ),
      );
      const {
        fulfilled,
        failed,
      }: HandleSendInvitesPayload = await handleSendInvites(updatedCollabs, accountId);
      setToastOpen(true);
      setToastMessage(createToastMessage(fulfilled, failed));

      if (failed > 0) {
        dispatch({ type: 'error' });
      } else {
        dispatch({ type: 'next' });
      }
    };
    if (sentData) {
      sendInvites();
    }
  }, [sentData]);

  useEffect(() => {
    if (inviteStep === inviteSteps.send) {
      setEmails([]);
    }
  }, [inviteStep]);

  if (!checkAccess(DashboardRole.ACCOUNT_MANAGER)) {
    return <Navigate to={`/accounts/${accountId}/people`} />;
  }

  useEffect(() => {
    dispatch({ type: 'updatedEmails', emails });
  }, [emails, invalidEmails]);

  if (error) {
    return <ErrorPage is404={false} includeFooter={false} />;
  }

  return (
    <>
      <ActionContent>
        {inviteStep === inviteSteps.input && (
          <div>
            <div>
              <TITLE_3>Invite collaborators</TITLE_3>
            </div>
            <AddCollaboratorEmails
              emails={emails}
              setEmails={setEmails}
              invalidEmails={invalidEmails}
              setInvalidEmails={setInvalidEmails}
            />
          </div>
        )}
        {(inviteStep !== inviteSteps.input) && (
          <div>
            <div>
              <TITLE_3>{inviteStep === inviteSteps.send ? 'Invitations sent!' : 'Invite collaborators'}</TITLE_3>
            </div>
            <ReviewCollaboratorDetails
              emails={emails}
              dataSent={inviteStep === inviteSteps.send}
              dispatch={dispatch}
              loading={inviteStep === inviteSteps.loading}
            />
          </div>
        )}
        <Toast open={toastOpen} setOpen={setToastOpen}>
          <BODY_SHORT_1>{toastMessage}</BODY_SHORT_1>
        </Toast>
      </ActionContent>

      {inviteStep === inviteSteps.send
        ? (
          <ActionFooter
            state={state}
            getPrimaryButtonProps={getPrimaryButtonProps}
            getSecondaryButtonProps={getSecondaryButtonProps}
            dispatch={dispatch}
          />
        )
        : (
          <ProgressActionFooter
            state={state}
            getPrimaryButtonProps={getPrimaryButtonProps}
            getSecondaryButtonProps={getSecondaryButtonProps}
            dispatch={dispatch}
            hideSecondaryButton={state.inviteStep === inviteSteps.input}
            progressDots={{ range: 2, current: inviteStep === inviteSteps.input ? 0 : 1 }}
          />
        )}
    </>
  );
}

export default AddCollaborators;
