import { classes, Icon } from '@whoop/web-components';
import styles from 'insights/components/widget/widget.module.scss';
import {
  Widget, WidgetType,
} from 'insights/components/widget/widget';
import Header from 'insights/components/widget/header/header';
import LoadingSkeleton from 'insights/components/loadingSkeleton/loadingSkeleton';
import { useWidgetQuery } from 'api/hooks/useWidgetQuery';
import LeaderboardTable from 'insights/components/leaderboardTable/leaderboardTable';
import PieChartAndLabels from 'insights/components/pieChartAndLabel/pieChartAndLabels';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import {
  BREAKDOWN_LABEL_BY_WIDGET,
  DEFAULT_SORT_BY_WIDGET,
  ICON_BY_WIDGET,
  COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET,
  WIDGET_TYPE_TO_NAME,
} from '../widgetFormatter';
import { insufficientDataMessage, privacyLevelMessage } from './keyStatisticWidgetConsts';

export type WidgetProps = {
  widgetType: WidgetType;
  toggleExpanded: (type: WidgetType) => void;
  isExpanded: boolean;
};

function KeyStatisticWidget(
  { widgetType, isExpanded, toggleExpanded }: WidgetProps,
) {
  const {
    data, isFetching, isLoading,
  } = useWidgetQuery(widgetType);
  const { isInPrivacyLevel } = usePrivacy();
  const noDataState = data?.leaderboard.length === 0;

  const showLeaderboard = isInPrivacyLevel(PrivacyLevel.primary_metrics) && !noDataState;

  const message = noDataState ? insufficientDataMessage : privacyLevelMessage;

  const isLoadingData = isLoading || isFetching;

  return (
    <Widget id={widgetType}>
      <Header
        title={WIDGET_TYPE_TO_NAME[widgetType]}
        onClick={() => toggleExpanded(widgetType)}
        isExpanded
      />
      <div className={styles.expandableCardContent}>
        {/* {isExpanded
          && (
            <div className={classes(styles.expandedContent, styles.expandedGraph)}>
              <LoadingSkeleton isLoading={isLoadingData}>
                Graph & Vow Section
              </LoadingSkeleton>
            </div>
          )}
        */}
        <div className={
          classes(
            styles.condensedContent,
            !isLoadingData && showLeaderboard && styles.gradient,
            showLeaderboard
              ? styles.contentHeight
              : styles.messageHeight,
          )
        }
        >
          <div className={classes(
            isExpanded && styles.expandedSpacing,
            isLoadingData && styles.loading,
          )}
          >
            <LoadingSkeleton isLoading={isLoadingData}>
              <div className={styles.breakdownChart}>
                <PieChartAndLabels
                  data={data?.breakdown}
                  labels={BREAKDOWN_LABEL_BY_WIDGET[widgetType]}
                  icon={ICON_BY_WIDGET[widgetType]}
                  noDataState={noDataState}
                />
              </div>
            </LoadingSkeleton>
          </div>
          <div className={classes(
            isLoadingData && showLeaderboard && styles.loadingLeaderboard,
            showLeaderboard ? styles.leaderboard : styles.message,
          )}
          >
            <LoadingSkeleton isLoading={isLoadingData}>
              {
                showLeaderboard
                  ? (
                    <LeaderboardTable
                      columns={COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET[widgetType]}
                      // columns={isExpanded
                      //   ? EXPANDED_LEADERBOARD_COLUMNS_BY_WIDGET[widgetType]
                      //   : COLLAPSED_LEADERBOARD_COLUMNS_BY_WIDGET[widgetType]}
                      data={data?.leaderboard}
                      defaultSortById={DEFAULT_SORT_BY_WIDGET[widgetType]}
                    />
                  )
                  : (
                    <div className={styles.messageContainer}>
                      <Icon
                        name={message.icon}
                        className={styles.icon}
                        data-testid={message.icon}
                      />
                      <p className={styles.messageText}>{message.text}</p>
                    </div>
                  )
              }
            </LoadingSkeleton>
          </div>
        </div>
      </div>
    </Widget>
  );
}

export default KeyStatisticWidget;
