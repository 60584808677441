import { useEffect, useMemo, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { usePrivacy } from 'context/privacy-context';
import { PrivacyLevel } from 'types/dashboardUser';
import Tabs, { TabThemes } from 'components/tabs/tabs';
import { useAccounts } from 'context/account-context';
import { getGroupById } from 'api/cohortApi';
import Breadcrumbs from 'components/breadcrumbs/breadcrumbs';
import DatePickerSelection from 'insights/components/datePickerSelection/datePickerSelection';
import { Col, Container, Row } from '@whoop/web-components';
import styles from './groupsHeader.module.scss';

export enum GroupsPageTabs {
  GROUPS = 'groups',
  INDIVIDUALS = 'individuals',
}

export type InsightsParams = {
  tab: string;
  groupId: string;
};

type Breadcrumb = {
  name: string;
  link: string;
};

function GroupsHeader() {
  const navigate = useNavigate();
  const { tab, groupId } = useParams<InsightsParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const { account } = useAccounts();

  const baseBreadcrumb: Breadcrumb = {
    name: 'My Groups',
    link: `/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}`,
  };
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([baseBreadcrumb]);

  const navToTeamsPage = () => {
    navigate(`/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}`);
  };

  const tabList = useMemo(() => {
    const baseTabs = [{ tab: GroupsPageTabs.GROUPS }];
    if (isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
      baseTabs.push({ tab: GroupsPageTabs.INDIVIDUALS });
    }
    return baseTabs;
  }, []);

  useEffect(() => {
    const fetchGroup = async () => {
      const group = await getGroupById(Number(groupId));
      setBreadcrumbs([
        baseBreadcrumb,
        {
          name: group.name,
          link: `/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}/${groupId}`,
        },
      ]);
    };

    if (groupId && isInPrivacyLevel(PrivacyLevel.aggregate_metrics)) {
      fetchGroup();
    } else {
      setBreadcrumbs([baseBreadcrumb]);
    }
  }, [groupId]);

  const handleTabClick = (newTab: GroupsPageTabs) => {
    if (newTab === GroupsPageTabs.GROUPS) {
      navToTeamsPage();
    } else {
      navigate(`/accounts/${account.id}/insights/${GroupsPageTabs.INDIVIDUALS}`);
    }
  };

  return (
    <Container fluid data-theme="dark" className={styles.teamsContainer}>
      <Row className={styles.header}>
        <Col xs={12}>
          <Tabs
            tabList={tabList}
            selectedTab={tab}
            onClick={(tabClicked : GroupsPageTabs) => handleTabClick(tabClicked)}
            theme={TabThemes.TEAMS}
          />
        </Col>
      </Row>
      {tab !== GroupsPageTabs.INDIVIDUALS && (
        <Row className={styles.breadcrumbsRow}>
          <Col xs={12} md={12} lg={6}>
            <Breadcrumbs breadcrumbs={breadcrumbs} />
          </Col>
          {isInPrivacyLevel(PrivacyLevel.aggregate_metrics) && (
          <Col xs={12} md={12} lg={6}>
            <DatePickerSelection />
          </Col>
          )}
        </Row>
      )}
      <Outlet />
    </Container>
  );
}

export default GroupsHeader;
