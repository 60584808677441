import TrendIndicatorBox from 'insights/components/trendIndicatorBox/trendIndicatorBox';
import { SENTIMENT } from 'api/groupLandingApi';
import styles from './groupsTableCells.module.scss';

export type Diff = {
  diff: number;
  sentiment: SENTIMENT;
};

type TrendCellProps = {
  roundedString: string;
  diffString: string;
  diff: Diff;
};

function TrendCell({ roundedString, diffString, diff }: TrendCellProps) {
  if (roundedString) {
    return (
      <div className={styles.percentWithTrend}>
        {roundedString}
        <TrendIndicatorBox diff={diff.diff} parsedDiff={diffString} trend={diff.sentiment} />
      </div>
    );
  }
  return null;
}

export function PercentTrendCell(value: number, diffObj: Diff) {
  const { diff } = diffObj;
  const roundedString = value ? `${Math.round(value)}%` : null;
  const diffString = diff ? `${Math.round(Math.abs(diff))}%` : '--%';
  return <TrendCell roundedString={roundedString} diffString={diffString} diff={diffObj} />;
}

export function StrainTrendCell(value: number, diffObj: Diff) {
  const { diff } = diffObj;
  // eslint-disable-next-line react/destructuring-assignment
  const roundedString = value ? value.toFixed(1) : null;
  const diffString = diff ? (Math.abs(diff)).toFixed(1) : '--';
  return <TrendCell roundedString={roundedString} diffString={diffString} diff={diffObj} />;
}
