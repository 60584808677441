import { Icon, classes } from '@whoop/web-components';
import { RecoveryLeaderboard, SleepLeaderboard, StrainLeaderboard } from 'api/widgetApi';
import { AccountParams } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import { useNavigate, useParams } from 'react-router-dom';
import { Column } from 'react-table';
import { PrivacyLevel } from 'types/dashboardUser';
import styles from './leaderboardTable.module.scss';
import useLeaderboardTable from './useLeaderboardTable';

type LeaderboardTableProps = {
  columns: Column[];
  data: RecoveryLeaderboard[] | StrainLeaderboard[] | SleepLeaderboard[];
  defaultSortById: string;
};

function LeaderboardTable({ columns, data, defaultSortById }: LeaderboardTableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useLeaderboardTable(data, columns, defaultSortById);
  const { accountId } = useParams<AccountParams>();
  const { isInPrivacyLevel } = usePrivacy();
  const navigate = useNavigate();

  const navigateToMemberProfile = (user_id: number) => {
    if (isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
      navigate(`/accounts/${accountId}/insights/profile/${user_id}`);
    }
  };

  // Future TODO : coloring and formatting based on value

  return (
    <table className={styles.leaderboardTable} {...getTableProps()}>
      <thead className={styles.tableHead}>
        {headerGroups.map((headerGroup) => (
          <tr className={styles.tableHeaderRow} {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                className={styles.tableHeader}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                <div className={classes(
                  styles.tableHeaderText,
                  !column.canSort && styles.leftAlign,
                )}
                >
                  {column.render('Header')}
                  {column.isSorted && <Icon name={column.isSortedDesc ? 'tiny_caret_down' : 'tiny_caret_up'} />}
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className={styles.tableBody} {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              className={classes(
                styles.tableRow,
                isInPrivacyLevel(PrivacyLevel.primary_metrics) && styles.clickRow,
              )}
              onClick={() => navigateToMemberProfile(row.original.user_id)}
            >
              {row.cells.map((cell) => (<td className={styles.widgetCell} {...cell.getCellProps()}>{cell.render('Cell')}</td>))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default LeaderboardTable;
