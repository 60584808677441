import { InviteStatus } from 'types/collaborator';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import {
  AcHubApp, Hub, HubSeatStatus, HubStatus, HubUser,
} from 'types/hub';
import { SeatStatus } from 'types/seat';
import { WhoopRole } from 'types/whoopRole';
import { AccountConfiguration } from 'types/admin';
import { baseSeats } from './seats';

const CURRENT_DATE = new Date();

export const getCurrentDate = () => new Date(CURRENT_DATE);

export const baseAccount = {
  id: 'foster-and-jefferson',
  account_name: 'Foster & Jefferson',
  total_licenses: 100,

  start_date: '2021-09-09',
  joined_date: '2021-09-09',
  end_date: '2030-09-08',
  primary_owner_name: 'Fernando Albertorio',
  primary_owner_email: 'fernando.albertorio@whoop.com',

  total_active_contracts: 1,
  expiration_date: '2030-09-08',

  active_seats: baseSeats.filter((seat) => seat.status === 'active').length,
  invited_seats: baseSeats.filter((seat) => seat.status === 'invited').length,
  pending_seats: baseSeats.filter((seat) => seat.status === 'pending').length,
};

export const assetsOnHand = 15;

export const configurations: AccountConfiguration = {
  account_id: baseAccount.id,
  bluetooth_roaming: false,
  concealed_feature_enabled: false,
  opt_out_enabled: false,
  concealed: false,
  hub_enabled: false,
  deidentified: false,
  privacy_ceiling_level: PrivacyLevel.all_metrics,
  strap_onboarding_enabled: true,
};

export const hubs: Hub[] = [
  {
    last_seen_at: getCurrentDate(),
    created_at: '2022-04-13T17:59:44.256+0000',
    updated_at: '2022-04-13T17:59:44.256+0000',
    mac_address: 'mac_address_1',
    salesforce_account_id: '123456',
    contract_number: null,
    name: 'Test Name1',
    description: 'test description',
    status: HubStatus.PENDING,
    network_id: 'd6797cf4-42b9-4cad-8591-9dd91c3f0fc3',
    roaming_enabled: true,
  },
  {
    last_seen_at: getCurrentDate(),
    created_at: '2022-02-01T20:26:02.621+0000',
    updated_at: '2022-04-05T13:59:52.704+0000',
    mac_address: 'mac_address_2',
    salesforce_account_id: '123456',
    contract_number: 1111,
    name: 'Test Name2',
    description: undefined,
    status: HubStatus.ONLINE,
    network_id: 'd6797cf4-42b9-4cad-8591-9dd91c3f0fc3',
    roaming_enabled: true,
  },
  {
    last_seen_at: getCurrentDate(),
    created_at: '2022-02-01T20:26:02.621+0000',
    updated_at: '2022-04-05T13:59:52.704+0000',
    mac_address: 'mac_address_3',
    salesforce_account_id: '123456',
    contract_number: 1111,
    name: 'Test Name3',
    description: undefined,
    status: HubStatus.OFFLINE,
    network_id: 'd6797cf4-42b9-4cad-8591-9dd91c3f0fc3',
    roaming_enabled: true,
  },
];

export const hubsInfo = [
  {
    name: 'Test Name1',
    description: 'test description',
    mac_address: 'mac_address_1',
    last_seen_at: getCurrentDate(),
    member_count: 12,
    status: HubStatus.PENDING,
  },
  {
    name: 'Test Name2',
    description: undefined,
    mac_address: 'mac_address_2',
    last_seen_at: getCurrentDate(),
    member_count: 25,
    status: HubStatus.ONLINE,
  },
  {
    name: 'Test Name3',
    description: undefined,
    mac_address: 'mac_address_3',
    last_seen_at: getCurrentDate(),
    member_count: 12,
    status: HubStatus.OFFLINE,
  },
];

export const appsInfo: AcHubApp[] = [
  {
    name: 'master',
    version: '3',
  },
  {
    name: 'master',
    version: '2',
  },
  {
    name: 'master',
    version: '1',
  },
];

export const hubUsers: HubUser[] = [
  {
    member_identifier: 'hubuser1',
    strap_serial: '4A0244496',
    status: HubSeatStatus.Active,
    last_connected: getCurrentDate(),
    battery_level: '20',
    last_uploaded: null,
    activated_on: '2022-07-11T19:08:10.145+0000',
    seat_id: 1,
  },
  {
    member_identifier: 'hubuser2',
    strap_serial: '4A0242841',
    status: HubSeatStatus.Active,
    last_connected: getCurrentDate(),
    battery_level: '100',
    last_uploaded: getCurrentDate(),
    activated_on: '2022-07-09T19:08:10.145+0000',
    seat_id: 2,
  },
  {
    member_identifier: 'hubuser3',
    strap_serial: '4A0244496',
    status: HubSeatStatus.Active,
    last_connected: getCurrentDate(),
    battery_level: '50',
    last_uploaded: null,
    activated_on: '2022-07-10T19:08:10.145+0000',
    seat_id: 3,
  },
  {
    member_identifier: 'hubuser4',
    strap_serial: '4A0242841',
    status: HubSeatStatus.Active,
    last_connected: getCurrentDate(),
    battery_level: '72',
    last_uploaded: getCurrentDate(),
    activated_on: '2022-05-12T19:08:10.145+0000',
    seat_id: 4,
  },
  {
    member_identifier: 'hubuser5',
    strap_serial: '4A0244496',
    status: HubSeatStatus.Active,
    last_connected: getCurrentDate(),
    battery_level: '20',
    last_uploaded: null,
    activated_on: '2022-06-05T19:08:10.145+0000',
    seat_id: 5,
  },
  {
    member_identifier: 'hubuser6',
    strap_serial: '4A0242841',
    status: HubSeatStatus.Active,
    last_connected: getCurrentDate(),
    battery_level: '100',
    last_uploaded: getCurrentDate(),
    activated_on: '2022-07-11T19:08:10.145+0000',
    seat_id: 6,
  },
];

export const accountAdminDashboardUser = {
  name: 'Test User 3',
  email: 'name3@server.com',
  dashboard_role: WhoopRole.ENTERPRISE_WORKSPACE_MANAGER,
  is_primary_owner: true,
  managing_groups: 0,
  avatar_url: '',
  display_role: 'Test Account Admin',
  salesforce_account_id: 'account_id',
  user_id: 123458,
  privacy_level: PrivacyLevel.all_metrics,
  role: DashboardRole.ACCOUNT_ADMIN,
};

// This should get deprecated once PermissionsUser is fully off the site
export const baseDashboardUsers = [
  {
    name: 'Test User 1',
    email: 'nam1@server.com',
    dashboard_role: WhoopRole.ENTERPRISE_COHORT_MANAGER,
    is_primary_owner: false,
    managing_groups: 0,
    avatar_url: '',
    display_role: 'Test Role',
    salesforce_account_id: 'account_id',
    user_id: 123456,
    privacy_level: PrivacyLevel.no_metrics,
    role: DashboardRole.GROUP_MANAGER,
  }, {
    name: 'Test User 2',
    email: 'name2@server.com',
    dashboard_role: WhoopRole.ENTERPRISE_WORKSPACE_MANAGER,
    is_primary_owner: false,
    managing_groups: 0,
    avatar_url: '',
    display_role: 'Test Role',
    salesforce_account_id: 'account_id',
    user_id: 123457,
    privacy_level: PrivacyLevel.no_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
  }, accountAdminDashboardUser,
];

export const invitedAndBouncedCollaborators = [
  {
    email: 'mika.rivas@fosterjefferson.com',
    display_role: 'Sales Manager',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_ADMIN,
    status: InviteStatus.invited,
  },
  {
    email: 'alejandro.navarro@fosterjefferson.com',
    display_role: 'HR Manager',
    privacy_level: PrivacyLevel.aggregate_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.invited,
  },
  {
    email: 'jamie.shields@fosterjefferson.com',
    display_role: 'HR Analyst',
    privacy_level: PrivacyLevel.aggregate_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.invited,
  },
  {
    email: 'tina.branch@fosterjefferson.com',
    display_role: 'Director of Talent',
    privacy_level: PrivacyLevel.aggregate_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.invited,
  },
  {
    email: 'brendan.marquez@fosterjefferson.com',
    display_role: 'Sports Performance',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.bounced,
  },
  {
    email: 'melinda.duke@fosterjefferson.com',
    display_role: 'Team Physician',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.bounced,
  },
];

export const baseCollaborators = [
  {
    name: 'Amy Baker',
    email: 'amy.baker@fosterjefferson.com',
    avatar_url: '',
    display_role: '',
    user_id: 123456,
    privacy_level: PrivacyLevel.no_metrics,
    role: DashboardRole.GROUP_MANAGER,
    status: InviteStatus.accepted,
  }, {
    name: 'Brian Simpson',
    email: 'brian.simpson@fosterjefferson.com',
    avatar_url: '',
    display_role: '',
    user_id: 123457,
    privacy_level: PrivacyLevel.no_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.accepted,
  }, {
    name: 'Test User 3',
    email: 'name3@server.com',
    avatar_url: '',
    display_role: 'Test Account Admin',
    user_id: 123458,
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_ADMIN,
    status: InviteStatus.accepted,
  },
  {
    email: 'liz.ross@fosterjefferson.com',
    display_role: '',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_ADMIN,
    status: InviteStatus.invited,
  },
  {
    email: 'tim.wilson@fosterjefferson.com',
    display_role: '',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_MANAGER,
    status: InviteStatus.invited,
  },
  {
    email: 'neil.turner@fosterjefferson.com',
    display_role: '',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.ACCOUNT_ADMIN,
    status: InviteStatus.bounced,
  },
  {
    email: 'caroline.gibson@fosterjefferson.com',
    display_role: '',
    privacy_level: PrivacyLevel.all_metrics,
    role: DashboardRole.GROUP_MANAGER,
    status: InviteStatus.bounced,
  },
];

export const straps = hubUsers.map((user) => user.strap_serial);

export const hubUserProfile = {
  member_identifier: '6835',
  first_name: 'Jane',
  last_name: 'Doe',
  username: 'janedoe',
  email: 'jane.doh@fosterjefferson.com',
  strap_serial: '4A0686835',
  birthday: '1988-06-16',
  gender: 'female',
  height: 1.6764,
  weight: 68.0388,
  hub_names: ['Hub_1'],
};

export const reidentificationTokenBody = {
  user_id: 1,
  salesforce_account_id: 'foster-and-jefferson',
  token: 'test-reidentification-token',
  expiration_date: 1663095909.645,
  created_at: 1663092309.645,
};

export const hubAccount = {
  hub_enabled: true,
  invited_seats: 0,
  active_seats: 0,
  joined_date: '2019-02-11',
  end_date: '2020-02-10',
  id: '123456',
  account_name: 'Par4Success',
  total_licenses: 0,
};

export const seatsByStatus = new Map<SeatStatus, any>([
  [SeatStatus.Active, baseSeats.filter((seat) => seat.status === SeatStatus.Active)],
  [SeatStatus.Invited, baseSeats.filter((seat) => seat.status === SeatStatus.Invited)],
  [SeatStatus.Pending, baseSeats.filter((seat) => seat.status === SeatStatus.Pending)],
  [SeatStatus.Deactivated, baseSeats.filter((seat) => seat.status === SeatStatus.Deactivated)],
]);

export const hubsByStatus = new Map<HubStatus, any>([
  [HubStatus.ALL, hubsInfo],
  [HubStatus.ONLINE, hubsInfo.filter((hub) => hub.status === HubStatus.ONLINE)],
  [HubStatus.OFFLINE, hubsInfo.filter((hub) => hub.status === HubStatus.OFFLINE)],
  [HubStatus.PENDING, hubsInfo.filter((hub) => hub.status === HubStatus.PENDING)],
  [HubStatus.AWAITING_ACTIVATION,
    hubsInfo.filter((hub) => hub.status === HubStatus.AWAITING_ACTIVATION)],
]);
