import Card from 'progress/components/Card/card';
import {
  PillarBreakdownDataWithMetric,
  RecoveryKeys,
  StrainKeys,
  PillarBreakdownDayData,
} from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarConsts';
import { DateRange } from 'react-day-picker';
import { useEffect, useState } from 'react';
import { analyticsClient } from 'api';
import { useParams } from 'react-router-dom';
import { getTrendBreakdownDays } from 'dataVisualizations/pillarBreakdownBar/pillarBreakdownBarUtils';
import { UrlParams } from 'types/profile';
import { ComputedDatum } from '@nivo/bar';
import CardHeader from 'progress/components/Card/cardHeader/cardHeader';
import { formatDate, getDateRangeString } from 'progress/profile/profileUtils';
import StrainRecoveryTrendGraph from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraph';
import { getBarDataFromTrend, getDaysInRange } from 'dataVisualizations/strainRecoveryTrendGraph/strainRecoveryTrendGraphUtils';
import { Pillars, StrainRecoveryPoints, TrendKey } from 'types/analytics';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import PillarBreakdownBarCard from 'progress/components/pillarBreakdownBarCard/pillarBreakdownBarCard';
import { Row, Col } from '@whoop/web-components';
import styles from './views.module.scss';

function RangeView() {
  const { userId } = useParams<UrlParams>();
  const { dateRange, comparisonDateRange } = useDateRange();
  const [trendData, setTrendData] = useState<StrainRecoveryPoints[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [daysToHighlight, setDaysToHighlight] = useState<string[]>([]);
  const [strainBreakdownDays, setStrainBreakdownDays] = useState<PillarBreakdownDayData>({});
  const [recoveryBreakdownDays, setRecoveryBreakdownDays] = useState<PillarBreakdownDayData>({});
  const [selectedKey, setSelectedKey] = useState<StrainKeys | RecoveryKeys>(null);

  const { formattedDate: formattedDateRange } = getDateRangeString(dateRange, comparisonDateRange);

  const getData = async (range: DateRange) => {
    setLoading(true);
    const { getRecoveryAndStrainTrend } = analyticsClient;
    const params = {
      memberId: parseInt(userId, 10),
      startDate: formatDate(range.from),
      endDate: formatDate(range.to),
    };

    const { data_trend } = await getRecoveryAndStrainTrend(
      params.memberId,
      params.startDate,
      params.endDate,
    );

    const {
      foundStrainBreakdownDays, foundRecoveryBreakdownDays,
    } = getTrendBreakdownDays(data_trend);

    setStrainBreakdownDays(foundStrainBreakdownDays);
    setRecoveryBreakdownDays(foundRecoveryBreakdownDays);

    if (data_trend?.length > 0) {
      const { strainData, recoveryData } = getBarDataFromTrend(
        getDaysInRange(dateRange.from, dateRange.to),
        data_trend,
      );
      setTrendData([
        { pointType: TrendKey.Strain, pointData: strainData },
        { pointType: TrendKey.Recovery, pointData: recoveryData },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    setSelectedKey(null);
    setDaysToHighlight([]);
    getData(dateRange);
  }, [dateRange]);

  // This will be used to highlight values on the strain vs. recovery graph when a bar is selected
  const barClickHandler = (datum: ComputedDatum<PillarBreakdownDataWithMetric>): void => {
    const pillar = datum.data.metric;
    const selectedVal = datum.id as StrainKeys | RecoveryKeys;

    if (pillar === 'day_strain') {
      if (daysToHighlight === strainBreakdownDays[selectedVal as StrainKeys]) {
        setDaysToHighlight([]);
        setSelectedKey(null);
      } else {
        setDaysToHighlight(strainBreakdownDays[selectedVal as StrainKeys]);
        setSelectedKey(selectedVal);
      }
    } else if (pillar === 'recovery') {
      if (daysToHighlight === recoveryBreakdownDays[selectedVal as RecoveryKeys]) {
        setDaysToHighlight([]);
        setSelectedKey(null);
      } else {
        setDaysToHighlight(recoveryBreakdownDays[selectedVal as RecoveryKeys]);
        setSelectedKey(selectedVal);
      }
    }
  };

  return (
    <>
      <Row className={styles.pillarBarRow}>
        <Col xs={4}>
          <PillarBreakdownBarCard
            pillar={Pillars.STRAIN}
            barClickHandler={barClickHandler}
            selectedKey={selectedKey}
            noMargin
          />
        </Col>
        <Col xs={4}>
          <PillarBreakdownBarCard
            pillar={Pillars.RECOVERY}
            barClickHandler={barClickHandler}
            selectedKey={selectedKey}
            noMargin
          />
        </Col>
        <Col xs={4}>
          <PillarBreakdownBarCard
            pillar={Pillars.SLEEP}
            selectedKey={selectedKey}
            noMargin
          />
        </Col>
      </Row>
      <Row className={styles.strainRecoveryTrendGraphContainer}>
        <Col xs={12}>
          <Card
            id="Strain & Recovery"
            loading={loading}
            showBackground={false}
          >
            <CardHeader
              title="Strain & Recovery"
              details={formattedDateRange}
            />
            <div className={styles.strainRecoveryTrendGraph}>
              <StrainRecoveryTrendGraph
                data={trendData}
                daysInRange={getDaysInRange(dateRange.from, dateRange.to).length}
                daysToHighlight={daysToHighlight}
              />
            </div>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default RangeView;
