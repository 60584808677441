import { makeStyles, Tooltip } from '@material-ui/core';
import { classes, Dropdown } from '@whoop/web-components';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import LoadingBar from 'components/loadingBar/loadingBar';
import Pagination from 'components/pagination';
import SearchBar from 'components/searchBar/searchBar';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import useBreakdownTable, { BreakdownDataRow } from 'progress/breakdown/breakdownTable';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Cell, Row } from 'react-table';
import SortIcon from 'tableUtils/sortIcon';
import {
  DeviceHealth, PillarOptions, Pillars,
} from 'types/analytics';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import { Cohort } from 'types/cohort';
import GroupsDropdown from 'progress/components/ProgressPageNav/groupsDropdown/groupsDropdown';
import { GroupsPageTabs } from 'insights/components/groupsHeader/groupsHeader';
import { hideAverageCell } from 'tableUtils/tableCells';
import DatePickerSelection from 'insights/components/datePickerSelection/datePickerSelection';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import styles from './individualsPage.module.scss';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'rgba(26, 34, 39, 0.9)',
    width: '322px',
    height: '18px',
    padding: '6px',
    textAlign: 'center',
    fontFamily: 'ProximaNova',
    fontSize: '14px',
  },
}));

export default function GroupsPage() {
  const { account, checkAccess } = useAccounts();
  const [searchParams, setSearchParams] = useSearchParams();
  const muiStyles = useStyles();
  const [groupSelected, setGroupSelected] = useState<Cohort>();
  const { accountId } = useParams();
  const { getFeatureFlag } = useFeatureFlags();
  const [viewBy, setViewBy] = useState<PillarOptions>(Pillars.SLEEP);
  const [pillars, setPillars] = useState<PillarOptions[]>([
    Pillars.SLEEP, Pillars.STRAIN, Pillars.RECOVERY]);
  const [searchTerm, setSearchTerm] = useState('');
  const { isInPrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const { dateRange } = useDateRange();
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    pinnedRow,
    prepareRow,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    state,
    setFilter,
    page,
    loading,
  } = useBreakdownTable(groupSelected?.id, viewBy, dateRange.from, dateRange.to ?? dateRange.from);

  useEffect(() => {
    async function setFF() {
      if (await getFeatureFlag('enterprise-device-health-fe') && checkAccess(DashboardRole.ACCOUNT_ADMIN)) {
        setPillars([...pillars, DeviceHealth.DEVICE_HEALTH]);
      }
    }

    if (!isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
      navigate(`/accounts/${account.id}/insights/${GroupsPageTabs.GROUPS}`);
    }

    setFF();
  }, []);

  const handleGroupSelect = (group: Cohort) => {
    setGroupSelected(group);
    if (group) {
      const params = new URLSearchParams(searchParams);
      params.set('groupId', group.id.toString());
      setSearchParams(params, { replace: true });
    }
  };

  const disableDates = viewBy === DeviceHealth.DEVICE_HEALTH;

  const handlePillarChange = (pillar: PillarOptions) => {
    setViewBy(pillar);
  };

  const onRowClick = (row: Row<BreakdownDataRow>) => {
    const userId = row.original.user_id;

    if (!row.original?.opted_in) {
      return;
    }

    if (!userId) {
      return;
    }

    if (!isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
      return;
    }

    navigate(`/accounts/${accountId}/insights/profile/${userId}`);
  };

  const renderAverageRow = () => {
    if (!pinnedRow) {
      return null;
    }

    prepareRow(pinnedRow);
    return (
      <tr {...pinnedRow.getRowProps()} className={styles.summaryRow}>
        {pinnedRow.cells.map((cell: Cell<BreakdownDataRow>) => (
          <td {...cell.getCellProps()}>
            {cell.row.original.hide_average && cell.column.id !== 'name' ? hideAverageCell() : cell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className={styles.container} data-testid="breakdown-v3">
      <div className={classes(styles.row, styles.options)}>
        <SearchBar
          placeholder="Search for a member..."
          setSearchTerm={(term) => setFilter('name', term)}
          value={searchTerm}
          setValue={setSearchTerm}
        />
        <GroupsDropdown
          groupSelected={groupSelected}
          setGroupSelected={handleGroupSelect}
          className={styles.filterDropdown}
        />
        <Dropdown
          data-testid="groups-dropdown"
          options={pillars}
          onOptionSelected={handlePillarChange}
          selectedOption={viewBy}
          theme="dark"
          className={classes(styles.filterDropdown, styles.capitalize)}
        />
        <Tooltip
          title="Only current data is provided for Device Health"
          disableHoverListener={!disableDates}
          classes={{
            tooltip: muiStyles.customTooltip,
          }}
        >
          {/** Tooltip requires being able to forward a ref to the immediate descendant.
           * Using a div simplifies this. */}
          <div>
            <DatePickerSelection disabled={disableDates} />
          </div>
        </Tooltip>
      </div>
      <table {...getTableProps()} className={styles.breakdownTable}>
        <thead>
          <tr>
            {headers.map((col) => (
              <th {...col.getHeaderProps()} {...col.getSortByToggleProps()}>
                <div className={styles.header}>
                  {col.render('Header')}
                  {col.isSorted && <SortIcon descending={col.isSortedDesc} />}
                </div>
              </th>
            ))}
          </tr>
          <tr className={styles.loadingRow}>
            <td colSpan={headers.length}>
              <LoadingBar visible={loading} />
            </td>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {renderAverageRow()}
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classes(
                  row.original.user_id
                  && isInPrivacyLevel(PrivacyLevel.primary_metrics)
                  && (row.original?.opted_in && styles.selectable),
                )}
                onClick={() => onRowClick(row)}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {
        rows.length
          ? (
            <Pagination
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              rangeStart={state.pageSize * state.pageIndex + 1}
              rangeEnd={Math.min(state.pageSize * (state.pageIndex + 1), rows.length)}
              total={rows.length}
            />
          )
          : <div className={styles.noData}>No data found</div>
      }
    </div>
  );
}
