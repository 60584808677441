import {
  BreakdownDTO,
  getSleepConsistencyBreakdown,
  getSleepDurationBreakdown,
  getSleepEfficiencyBreakdown,
  getSleepPerformanceBreakdown,
} from 'api/breakdownApi';
import { getGroupMembers } from 'api/cohortApi';
import TeamAvatar from 'assets/icons/TeamAvatar.svg';
import { getSleepDuration } from 'progress/utils/numberFormatter';
import { Cell, Column, TableState } from 'react-table';
import { breakdownTableNameWithAvatarCell } from 'tableUtils/tableCells';
import { GroupMember } from 'types/cohort';
import { PrivacyLevel } from 'types/dashboardUser';
import { checkOptInInfoCell, roundedPercent, scaledSleepEfficiency } from './cells';

export interface SleepBreakdownData {
  user_id?: number;
  first_name: string;
  last_name: string;
  avatar_url?: string;
  member_identifier?: string;
  performance?: number;
  duration?: number;
  sleep_efficiency?: number;
  sleep_consistency?: number;
  opted_in?: boolean;
  hide_average?: boolean;
}

type ColumnName = 'name' | 'sleepPerformance' | 'hoursOfSleep' | 'sleepConsistency' | 'sleepEfficiency';

const columns: Record<ColumnName, Column<SleepBreakdownData>> = {
  name: {
    Header: 'Name',
    accessor: (data) => (data.first_name + data.last_name).toUpperCase(),
    id: 'name',
    Cell: breakdownTableNameWithAvatarCell,
  },
  sleepPerformance: {
    Header: 'Sleep performance',
    accessor: 'performance',
    Cell: (cell: Cell<SleepBreakdownData>) => checkOptInInfoCell(
      roundedPercent(cell),
      cell.row.original?.opted_in,
    ),
  },
  hoursOfSleep: {
    Header: 'Hours of sleep',
    accessor: 'duration',
    Cell: (cell: Cell<SleepBreakdownData>) => checkOptInInfoCell(
      cell?.value ? getSleepDuration(cell?.value, 's') : null,
      cell.row.original?.opted_in,
    ),
  },
  sleepConsistency: {
    Header: 'Sleep consistency',
    accessor: 'sleep_consistency',
    Cell: (cell: Cell<SleepBreakdownData>) => checkOptInInfoCell(
      roundedPercent(cell),
      cell.row.original?.opted_in,
    ),
  },
  sleepEfficiency: {
    Header: 'Sleep efficiency',
    accessor: 'sleep_efficiency',
    Cell: (cell: Cell<SleepBreakdownData>) => checkOptInInfoCell(
      scaledSleepEfficiency(cell),
      cell.row.original?.opted_in,
    ),
  },
};

export function getSleepTableColumns(privacyLevel: PrivacyLevel) {
  switch (privacyLevel) {
    case PrivacyLevel.all_metrics:
      return [
        columns.name,
        columns.sleepPerformance,
        columns.hoursOfSleep,
        columns.sleepConsistency,
        columns.sleepEfficiency,
      ];
    case PrivacyLevel.primary_metrics:
    case PrivacyLevel.performance_metrics:
      return [
        columns.name,
        columns.sleepPerformance,
        columns.hoursOfSleep,
      ];
    default:
      return [];
  }
}

function createSleepBreakdownData(groupMembers: GroupMember[], sleepPerformance: BreakdownDTO<'sleepPerformance'>, sleepDuration: BreakdownDTO<'sleepDuration'>, sleepConsistency?: BreakdownDTO<'sleepConsistency'>, sleepEfficiency?: BreakdownDTO<'sleepEfficiency'>) {
  if (groupMembers.length < 1) {
    return [];
  }

  const groupAverage: SleepBreakdownData = {
    first_name: 'Team',
    last_name: 'Average',
    avatar_url: TeamAvatar,
    hide_average: sleepPerformance.hide_average
    && sleepDuration.hide_average
    && sleepConsistency.hide_average
    && sleepEfficiency.hide_average,
    performance: sleepPerformance.average,
    duration: sleepDuration.average,
    sleep_consistency: sleepConsistency?.average,
    sleep_efficiency: sleepEfficiency?.average,
  };

  const performanceMap = sleepPerformance.user_data.reduce(
    (map, data) => map.set(data.user_id, data.data_value),
    new Map<number, number | null>(),
  );
  const durationMap = sleepDuration.user_data.reduce(
    (map, data) => map.set(data.user_id, data.data_value),
    new Map<number, number | null>(),
  );
  const consistencyMap = sleepConsistency?.user_data.reduce(
    (map, data) => map.set(data.user_id, data.data_value),
    new Map<number, number | null>(),
  );
  const efficiencyMap = sleepEfficiency?.user_data.reduce(
    (map, data) => map.set(data.user_id, data.data_value),
    new Map<number, number | null>(),
  );

  const userRows: SleepBreakdownData[] = groupMembers.map((m) => ({
    user_id: m.user_id,
    first_name: m.first_name,
    last_name: m.last_name,
    avatar_url: m.avatar_url,
    member_identifier: m.member_identifier,
    performance: performanceMap.get(m.user_id),
    duration: durationMap.get(m.user_id),
    sleep_consistency: consistencyMap?.get(m.user_id),
    sleep_efficiency: efficiencyMap?.get(m.user_id),
    opted_in: m.opted_in,
  }));
  return [groupAverage, ...userRows];
}

export async function getSleepData(
  groupId: number,
  start: Date,
  end: Date,
  privacyLevel: PrivacyLevel,
) {
  switch (privacyLevel) {
    case PrivacyLevel.all_metrics: {
      const metrics = await Promise.all([
        getGroupMembers(groupId),
        getSleepPerformanceBreakdown(groupId, start, end),
        getSleepDurationBreakdown(groupId, start, end),
        getSleepConsistencyBreakdown(groupId, start, end),
        getSleepEfficiencyBreakdown(groupId, start, end),
      ]);

      return createSleepBreakdownData(...metrics);
    }
    case PrivacyLevel.primary_metrics:
    case PrivacyLevel.performance_metrics: {
      const metrics = await Promise.all([
        getGroupMembers(groupId),
        getSleepPerformanceBreakdown(groupId, start, end),
        getSleepDurationBreakdown(groupId, start, end),
      ]);

      return createSleepBreakdownData(...metrics);
    }
    default:
      return [];
  }
}

export const initialSleepTableState: Partial<TableState> = {
  sortBy: [{ id: 'performance', desc: true }],
};
