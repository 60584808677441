import { makeStyles, Tooltip } from '@material-ui/core';
import { classes, RadioToggle } from '@whoop/web-components';
import DatePicker from 'components/datePicker/datePicker';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import FilterDropdown from 'components/filterDropdown/filterDropdown';
import LoadingBar from 'components/loadingBar/loadingBar';
import Pagination from 'components/pagination';
import SearchBar from 'components/searchBar/searchBar';
import { HEADING_2 } from 'components/text';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import dayjs from 'dayjs';
import { trackEvent } from 'helpers/analyticsTracking';
import { useEffect, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useNavigate, useParams } from 'react-router';
import { Cell, Row } from 'react-table';
import SortIcon from 'tableUtils/sortIcon';
import { hideAverageCell } from 'tableUtils/tableCells';
import {
  DeviceHealth, Period, PillarOptions, Pillars,
} from 'types/analytics';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import styles from './breakdown.module.scss';
import useBreakdownTable, { BreakdownDataRow } from './breakdownTable';

const useStyles = makeStyles(() => ({
  customTooltip: {
    backgroundColor: 'rgba(26, 34, 39, 0.9)',
    width: '322px',
    height: '18px',
    padding: '6px',
    textAlign: 'center',
    fontFamily: 'ProximaNova',
    fontSize: '14px',
  },
}));

interface BreakdownProps {
  groupId: number;
  groupName: string;
}

export default function Breakdown({ groupId, groupName }: BreakdownProps) {
  const { checkAccess } = useAccounts();
  const muiStyles = useStyles();
  const { accountId } = useParams();
  const { getFeatureFlag } = useFeatureFlags();
  const [viewBy, setViewBy] = useState<PillarOptions>(Pillars.SLEEP);
  const [fromDate, setFromDate] = useState(dayjs().startOf('day').toDate());
  const [toDate, setToDate] = useState<Date | null>(null);
  const [toggle, setToggle] = useState<Period | null>(Period.TODAY);
  const [pillars, setPillars] = useState<PillarOptions[]>([
    Pillars.SLEEP, Pillars.STRAIN, Pillars.RECOVERY]);
  const [searchTerm, setSearchTerm] = useState('');
  const { isInPrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const {
    getTableProps,
    getTableBodyProps,
    headers,
    rows,
    pinnedRow,
    prepareRow,
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    state,
    setFilter,
    page,
    loading,
  } = useBreakdownTable(groupId, viewBy, fromDate, toDate ?? fromDate);

  useEffect(() => {
    async function setFF() {
      if (await getFeatureFlag('enterprise-device-health-fe') && checkAccess(DashboardRole.ACCOUNT_ADMIN)) {
        setPillars([...pillars, DeviceHealth.DEVICE_HEALTH]);
      }
    }

    setFF();
  }, []);

  const disableDates = viewBy === DeviceHealth.DEVICE_HEALTH;

  const handleDateRangeChange = (dateRange: DateRange) => {
    if (!dateRange) { // TODO datepicker validation
      return;
    }

    setFromDate(dateRange.from);
    setToDate(dateRange.to);

    trackEvent('Breakdown Tab - Quick Period Change', {
      accountId,
      period: dateRange,
    });

    const from = dayjs(dateRange.from);
    const to = dayjs(dateRange.to);
    const today = dayjs().startOf('day');

    if (!dateRange.to && from.isSame(today)) {
      setToggle(Period.TODAY);
    } else if (from.isSame(today.subtract(7, 'days')) && to.isSame(today)) {
      setToggle(Period.LAST_WEEK);
    } else if (from.isSame(today.subtract(30, 'days')) && to.isSame(today)) {
      setToggle(Period.LAST_MONTH);
    } else {
      setToggle(null);
    }
  };

  const handleToggle = (period: Period) => {
    const today = dayjs().startOf('day');
    switch (period) {
      case Period.TODAY:
        setFromDate(today.toDate());
        setToDate(null);
        break;
      case Period.LAST_WEEK:
        setFromDate(today.subtract(7, 'days').toDate());
        setToDate(today.toDate());
        break;
      case Period.LAST_MONTH:
        setFromDate(today.subtract(30, 'days').toDate());
        setToDate(today.toDate());
        break;
      default:
        throw new Error(`Undefined period ${period}`);
    }

    setToggle(period);
  };

  const handlePillarChange = (pillar: PillarOptions) => {
    setViewBy(pillar);

    trackEvent('Breakdown Tab - Filter by Area', {
      accountId,
      groupId,
      pillar,
    });

    if (pillar === DeviceHealth.DEVICE_HEALTH) {
      handleDateRangeChange({ from: dayjs().startOf('day').toDate(), to: null });
    }
  };

  const onRowClick = (row: Row<BreakdownDataRow>) => {
    const userId = row.original.user_id;

    if (!row.original?.opted_in) {
      return;
    }

    if (!userId) {
      return;
    }

    if (!isInPrivacyLevel(PrivacyLevel.primary_metrics)) {
      return;
    }

    navigate(`/accounts/${accountId}/progress/${userId}`);
  };

  const renderAverageRow = () => {
    if (!pinnedRow) {
      return null;
    }

    prepareRow(pinnedRow);
    return (
      <tr {...pinnedRow.getRowProps()} className={styles.summaryRow}>
        {pinnedRow.cells.map((cell: Cell<BreakdownDataRow>) => (
          <td {...cell.getCellProps()}>
            {cell.row.original.hide_average && cell.column.id !== 'name' ? hideAverageCell() : cell.render('Cell')}
          </td>
        ))}
      </tr>
    );
  };

  return (
    <div className={styles.container} data-testid="breakdown-v3">
      <div className={styles.row}>
        <HEADING_2>
          {`Breakdown for ${groupName}`}
        </HEADING_2>
      </div>
      <div className={styles.row}>
        <SearchBar
          placeholder="Search for a member..."
          setSearchTerm={(term) => setFilter('name', term)}
          value={searchTerm}
          setValue={setSearchTerm}
        />
      </div>
      <div className={styles.row}>
        <FilterDropdown
          heading={`View By: ${viewBy}`}
          ariaLabel="view by"
          options={pillars.map((p) => ({
            name: p,
            active: p === viewBy,
            onClick: () => handlePillarChange(p),
          }))}
        />
        <Tooltip
          title="Only current data is provided for Device Health"
          disableHoverListener={!disableDates}
          classes={{
            tooltip: muiStyles.customTooltip,
          }}
        >
          <div className={styles.dateSelector} data-theme="light">
            <DatePicker
              currentRange={{ from: fromDate, to: toDate }}
              applyRange={handleDateRangeChange}
              disabled={disableDates}
            />
            <RadioToggle
              options={[
                {
                  label: Period.TODAY,
                  value: Period.TODAY,
                },
                {
                  label: Period.LAST_WEEK,
                  value: Period.LAST_WEEK,
                },
                {
                  label: Period.LAST_MONTH,
                  value: Period.LAST_MONTH,
                },
              ]}
              value={toggle}
              onChange={handleToggle}
              className={styles.radioToggle}
              size="small"
              onClick={(event: Event) => {
                if (disableDates) {
                  event.preventDefault();
                }
              }}
              useExternalState
              allowNone
              name="period"
            />
          </div>
        </Tooltip>
      </div>
      <table {...getTableProps()} className={styles.breakdownTable}>
        <thead>
          <tr>
            {headers.map((col) => (
              <th {...col.getHeaderProps()} {...col.getSortByToggleProps()}>
                <div className={styles.header}>
                  {col.render('Header')}
                  {col.isSorted && <SortIcon descending={col.isSortedDesc} />}
                </div>
              </th>
            ))}
          </tr>
          <tr className={styles.loadingRow}>
            <td colSpan={headers.length}>
              <LoadingBar visible={loading} />
            </td>
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {renderAverageRow()}
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className={classes(
                  row.original.user_id
                  && isInPrivacyLevel(PrivacyLevel.primary_metrics)
                  && (row.original?.opted_in && styles.selectable),
                )}
                onClick={() => onRowClick(row)}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {
        rows.length
          ? (
            <Pagination
              previousPage={previousPage}
              canPreviousPage={canPreviousPage}
              nextPage={nextPage}
              canNextPage={canNextPage}
              rangeStart={state.pageSize * state.pageIndex + 1}
              rangeEnd={Math.min(state.pageSize * (state.pageIndex + 1), rows.length)}
              total={rows.length}
            />
          )
          : <div className={styles.noData}>No data found</div>
      }
    </div>
  );
}
