import { CustomLayerProps } from '@nivo/line';
import { Icon, Colors } from '@whoop/web-components';
import { useNavigate } from 'react-router-dom';
import { OverviewGraphAnnotation } from 'dataVisualizations/heartRateLineGraph/hrLineGraphTypes';
import { convertActivityNameToIconName } from '@whoop/web-commons';
import styles from './layers.module.scss';

export function ActivityButtonLayer(
  { series, xScale, yScale }: CustomLayerProps,
): React.ReactNode {
  const navigate = useNavigate();
  const activityList = [];
  const yVal = yScale(225) as number - 70;
  const rectWidth = 84;
  const squareWidth = 40;
  const iconWidth = 8;

  // For all the values in series, if they don't have annotationData,
  // then they are part of the line graph, and we want to isolate just the activity data
  // so we can render the activity rectangles at the top of the graph.
  // For each object of the activity data, we pull out:
  // the annotation data (which includes info like the activity name),
  // the activity color, and important points like:
  // leftBound: the point on the graph in which the activity began
  // rightBound: the point on the graph in which the activity ended
  // midPoint: the point on the graph in the middle of the activity
  const activities = series.map((line: any) => {
    if (!line.annotationData) {
      return null;
    }
    const { data, annotationData, color } = line;
    const numElements = data.length;

    // activity title has '-' instead of spaces in the backend
    annotationData.title = annotationData.title.replaceAll(/[-_]/g, ' ');

    // if activity has no heart rate data, we don't want to display it
    if (numElements === 0) {
      return null;
    }

    // Handle case if the activity only spans 1 minute --> numElements = 1
    if (numElements === 1) {
      return {
        annotationData,
        color,
        leftBound: xScale(data[0].data.x) as number,
        rightBound: xScale(data[0].data.x) as number,
        midpoint: xScale(data[0].data.x) as number,
        key: line.id,
      };
    }

    return {
      annotationData,
      color,
      leftBound: xScale(data[0].data.x) as number,
      rightBound: xScale(data[numElements - 1].data.x) as number,
      midpoint: xScale(data[Math.round(numElements / 2)].data.x) as number - 36,
      key: line.id,
    };
  }).filter((activity) => activity != null);

  // When fullDetails is false, this method returns the small version for the activity rectangle
  // When fullDetails is true, this method returns the large version,
  // which includes the duration, and activity name
  const activityRect = (
    annotationData: OverviewGraphAnnotation,
    key: string,
    color: string,
    midpoint: number,
    iconMidpoint: number,
    fullDetails: boolean,
  ) => (
    <g
      onClick={() => { navigate(annotationData.link); }}
      className={styles.clickableAnnotation}
      key={key}
    >
      {fullDetails && (
        <text
          fill={color}
          textAnchor="middle"
          x={midpoint + 42}
          y={yVal - 8}
        >
          {annotationData.title}
        </text>
      )}
      <rect
        x={midpoint}
        y={yVal}
        width={fullDetails ? rectWidth : squareWidth}
        height={squareWidth}
        rx="4"
        fill={color}
      />

      <Icon
        className={styles.activityIcon}
        width="24"
        height="24"
        name={convertActivityNameToIconName(annotationData.activityName)}
        x={iconMidpoint}
        y={yVal + 8}
      />
      {fullDetails && (<text className={styles.durationText} fill={Colors.white} textAnchor="middle" x={midpoint + 58} y={yVal + 26}>{annotationData.duration}</text>)}
    </g>
  );

  for (let i = 0; i < activities.length; i += 1) {
    const {
      annotationData, key, color, leftBound, midpoint,
    } = activities[i];

    // Default to the large version of activity rectangle for first and last activities
    if (i === 0 || i === activities.length - 1) {
      activityList.push(activityRect(annotationData, key, color, midpoint, midpoint + 8, true));
    } else {
      const currXVal = midpoint;
      const nextXVal = activities[i + 1].midpoint;

      // difference between midpoints of current and next activity rectangle
      const difference = nextXVal - currXVal;

      // If the difference is less than the largest possible distance before activities
      // start to overlap we should stick two small rectangles next to each other,
      // centered around the midpoint of both activities
      if (difference < 56) {
        const {
          annotationData: nextAnnotationData,
          key: nextKey, color: nextColor, rightBound: nextRightBound,
        } = activities[i + 1];
        const combinedMidpoint = (nextRightBound - leftBound) / 2 + leftBound;
        const gap = 1;

        activityList.push(activityRect(
          annotationData,
          key,
          color,
          combinedMidpoint - squareWidth - gap, // 1 px gap between squares
          combinedMidpoint - squareWidth + iconWidth - gap,
          false,
        ));
        activityList.push(activityRect(
          nextAnnotationData,
          nextKey,
          nextColor,
          combinedMidpoint,
          combinedMidpoint + iconWidth,
          false,
        ));

        i += 1; // Skip the next one. It will stick with current
      } else if (difference < rectWidth + 3) {
        // Show the small version of the activity rectangle
        const offset = 15;
        activityList.push(
          activityRect(
            annotationData,
            key,
            color,
            currXVal + offset,
            currXVal + iconWidth + offset,
            false,
          ),
        );
      } else {
        // Show the large version of the activity rectangle
        activityList.push(activityRect(
          annotationData,
          key,
          color,
          currXVal,
          currXVal + iconWidth,
          true,
        ));
      }
    }
  }

  return (
    <g>
      {activityList.map((activity) => (
        <g key={activity.key}>
          {activity}
        </g>
      ))}
    </g>
  );
}
