import { AxisTickProps } from '@nivo/axes';
import { Colors } from '@whoop/web-components';
import { getHourFromEpoch } from 'dataVisualizations/utils/utils';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { getMonthDayValues } from 'progress/utils/date';
import { MonthDayValues } from 'types/analytics';
import styles from './axis.module.scss';
import { getAxisTickColor } from './utils';

// Needed to import this way so that there weren't type errors with jest testing
dayjs.extend(duration);

export function labelXAxis(
  tick: AxisTickProps<string>,
  gridXValues: number[],
) {
  const {
    x, y, value,
  } = tick;

  if (gridXValues.includes(Number(value))) {
    const hourFromEpoch = getHourFromEpoch(Number(value));
    const axisOffset = `translate(${x},${y + 18})`;
    return (
      <g transform={axisOffset}>
        <text
          textAnchor="middle"
          dominantBaseline="middle"
          className={styles.durationAxis}
          fill={Colors.gray700}
        >
          <tspan x="0">
            {hourFromEpoch.hour}
          </tspan>
          <tspan x="0" dy="18px">
            {hourFromEpoch.amPm}
          </tspan>
        </text>
      </g>
    );
  }
  return <g />;
}

export function DayYTick(
  x: number,
  y: number,
  value: number,
  tickFormatter?: (val: number) => string,
) {
  return (
    <g transform={`translate(${x - 20},${y})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.yAxisTick}
        fill={Colors.blackAlpha500}
      >
        <tspan>
          {tickFormatter ? tickFormatter(value) : value}
        </tspan>
      </text>
    </g>
  );
}

export function DayMonthXAxisTick(
  tick: AxisTickProps<string>,
  showDay: boolean = false,
) {
  const { x, y, value } = tick;
  const { day, month, dayNum }: MonthDayValues = getMonthDayValues(value);

  return (
    <g transform={`translate(${x},${y + 14})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.dayMonthAxisTick}
        fill="#808080"
      >
        <tspan x="0" dy="4px">
          {showDay ? day : month}
        </tspan>
        <tspan x="0" dy="18px">
          {dayNum}
        </tspan>
      </text>
    </g>
  );
}

export function RawHeartRateAxis(tick: AxisTickProps<string>) {
  const { x, y, value } = tick;
  // 6 is ~ the pixel width of one digit, so we multiply the number of digits in the axis value
  // The 16 and 5 are just offset values that position the value where we want it to be displayed
  const axisOffset = `translate(${x - (16 + (value.toString().length * 6))},${y + 5})`;

  return (
    <g fill={Colors.gray700} transform={axisOffset}>
      <text className={styles.heartRateAxis}>
        {value}
      </text>
    </g>
  );
}

/*
  Zones of max bar graph axes utils
 */

export function CustomZoneTick(x: number, y: number, index: number, zoneTickLabels: string[]) {
  return (
    <g transform={`translate(${x + 64},${y})`}>
      <text
        textAnchor="end"
        dominantBaseline="middle"
        className={styles.tick}
        fill={Colors.blackAlpha300}
      >
        {zoneTickLabels[index]}
      </text>
    </g>
  );
}

export function CustomHorizontalBarTick(
  x: number,
  y: number,
  val: number,
  tickFormatter: (val: number) => string,
) {
  return (
    <g transform={`translate(${x + 32},${y + 2})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.tick}
      >
        <tspan>
          {tickFormatter(val)}
        </tspan>
      </text>
    </g>
  );
}

/* in order to add more padding to the y-axis from the datapoints we need to
1) position the axis label away from the graph
2) extend the grid line from the axis label to the graph
so it doesn't look like the label is floating in space
The grid lines themselves cannot be extended to the axis so we need to create a line that will
extend from the axis label back to the grid line
*/
export function StrainRecoveryTick(
  x: number,
  y: number,
  value: string,
  strain: boolean,
) {
  const STRAIN_MAX = 21;
  const percentage = Number(((Number(value) / STRAIN_MAX) * 100).toFixed());

  // setting the x value depending on strain or recovery axis
  let transformXVal = 0;
  // if extendedGridLine is true
  // we want to position the x value of the axis label further away from the graph
  // recovery axis on the right side
  transformXVal = !strain ? x + 76 : x - 16;
  // strain axis on the left side
  transformXVal = strain ? transformXVal - 60 : transformXVal;

  return (
    <g transform={`translate(${transformXVal},${y})`}>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        className={styles.strainRecoveryTick}
        fill={getAxisTickColor(strain, percentage)}
      >
        {strain ? value : `${percentage}%`}
      </text>
      {strain
        // x1 = 0 would be at at the center of the text
        // left axis so we move in the positive direction
        ? <line opacity="1" x1={20} x2={76} y1={0} y2={0} stroke="#dddddd" strokeWidth=".5" />
        // right axis so we move in the negative direction
        : <line opacity="1" x1={-20} x2={-76} y1={0} y2={0} stroke="#dddddd" strokeWidth=".5" />}
    </g>
  );
}
