import { MenuItem } from '@material-ui/core';
import { Icon } from '@whoop/web-components';
import { cohortClient } from 'api';
import Dropdown from 'components/dropdown';
import { HEADING_4 } from 'components/text';
import { useAccounts } from 'context/account-context';
import { usePrivacy } from 'context/privacy-context';
import { trackEvent } from 'helpers/analyticsTracking';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Cohort } from 'types/cohort';
import { PrivacyLevel } from 'types/dashboardUser';
import ProgressPageTabs from 'types/progressPage';
import { UseStatePropType } from 'types/useStatePropType';
import Tabs, { TabThemes } from 'components/tabs/tabs';
import styles from './progressPageNav.module.scss';

type ProgressPageNavProps = {
  tabSelected: ProgressPageTabs;
  groupSelected: Cohort;
  setGroupSelected: UseStatePropType<Cohort>;
};

function ProgressPageNav({
  tabSelected, groupSelected, setGroupSelected,
}: ProgressPageNavProps) {
  const [searchParams] = useSearchParams();
  const { account } = useAccounts();
  const [groups, setGroups] = useState<Cohort[]>();
  const { isInPrivacyLevel } = usePrivacy();
  const navigate = useNavigate();
  const tabList = [{ tab: ProgressPageTabs.SUMMARY }];

  useEffect(() => {
    const init = async () => {
      const data: Cohort[] = await cohortClient.getGroupsByUserAccess(true);
      const primaryGroup = data.find((group: Cohort) => group.is_primary);
      let groupToSelect;
      let groupsData;
      if (primaryGroup) {
        // Rename primary group
        primaryGroup.name = 'All Groups';
        // Remove primary group from array and then add it back at the front
        // so it's at the top of the dropdown list
        const dataWithoutPrimary = data.filter((group: Cohort) => !group.is_primary);
        dataWithoutPrimary.unshift(primaryGroup);
        groupToSelect = primaryGroup;
        groupsData = dataWithoutPrimary;
      } else {
        const [destructuredFirstData] = data;
        groupToSelect = destructuredFirstData;
        groupsData = data;
      }
      if (searchParams.get('groupId')) {
        const loadedGroup = data.find((group: Cohort) => group.id === parseInt(searchParams.get('groupId'), 10));
        groupToSelect = loadedGroup;
      }
      setGroupSelected(groupToSelect);
      setGroups(groupsData);
    };
    if (account) {
      init();
    }
    return () => {
      setGroupSelected(null);
      setGroups(null);
    };
  }, [account]);

  if (isInPrivacyLevel(PrivacyLevel.primary_metrics) && groups?.length > 0) {
    tabList.push({ tab: ProgressPageTabs.BREAKDOWN });
  }

  const handleGroupSelect = (group: Cohort) => {
    trackEvent(`Progress ${tabSelected} Tab - Group Selected`, {
      accountId: account.id,
      groupId: group.id,
    });
    setGroupSelected(group);
    navigate(`/accounts/${account.id}/progress${tabSelected === ProgressPageTabs.BREAKDOWN ? '/breakdown' : ''}?groupId=${group.id}`);
  };

  const handleTabClick = (tab: ProgressPageTabs) => {
    if (tab === ProgressPageTabs.SUMMARY) {
      navigate(`/accounts/${account.id}/progress?groupId=${groupSelected.id}`);
    } else {
      navigate(`/accounts/${account.id}/progress/${ProgressPageTabs.BREAKDOWN}?groupId=${groupSelected.id}`);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.tabs}>
        <Tabs
          tabList={tabList}
          selectedTab={tabSelected}
          onClick={(tab) => handleTabClick(tab)}
          theme={TabThemes.HEADER}
        />
      </div>
      {groups?.length > 0 && groupSelected && (
        <div className={styles.groupsDropdown}>
          <Dropdown
            button={(
              <div className={styles.dropdownButton}>
                <HEADING_4>
                  {`Display: ${groupSelected.name}`}
                </HEADING_4>
                <Icon name="tiny_triangle_down" className={styles.dropdownIcon} />
              </div>
            )}
            buttonProps={{ variant: 'link' }}
            closeOnClick
          >
            <div>
              {groups.map((group: Cohort) => (
                <MenuItem
                  key={group.id}
                  className={
                    groupSelected.id === group.id ? styles.activeItem : styles.inactiveItem
                  }
                  onClick={() => handleGroupSelect(group)}
                >
                  {groupSelected.id === group.id && <Icon name="check" className={styles.activeIcon} />}
                  <div className={styles.dropdownItemLabel}>{group.name}</div>
                </MenuItem>
              ))}
            </div>
          </Dropdown>
        </div>
      )}
    </div>
  );
}

export default ProgressPageNav;
