import { client } from 'api/apiClient';

export enum GroupsLandingStatNames {
  SCALED_DAY_STRAIN = 'SCALED_DAY_STRAIN',
  SLEEP_PERFORMANCE = 'SLEEP_PERFORMANCE',
  RECOVERY_SCORE = 'RECOVERY_SCORE',
}

export enum SENTIMENT {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
  NEUTRAL = 'NEUTRAL',
}

export type AverageStat = {
  name: GroupsLandingStatNames;
  val: number;
  diff: number;
  sentiment: SENTIMENT;
};

export type LandingGroup = {
  id: number;
  name: string;
  member_count: number;
  average_hidden: boolean;
  average_stats: AverageStat[];
};

export type GroupLanding = {
  salesforce_account_id: string;
  salesforce_account_name: string;
  start_date: string;
  end_date: string;
  groups: LandingGroup[];
};

type GroupLandingParams = {
  salesforceAccountId: string;
  startDate: string;
  endDate: string;
};

export const getGroupLanding = async (params: GroupLandingParams) => {
  const response = await client.get<GroupLanding>('enterprise-service/v1/groups/landing', { params });
  return response.data;
};
