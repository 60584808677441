import {
  BreakdownDTO, BreakdownMetricType, getHrvBreakdown, getRecoveryScoreBreakdown, getRhrBreakdown,
} from 'api/breakdownApi';
import { getGroupMembers } from 'api/cohortApi';
import TeamAvatar from 'assets/icons/TeamAvatar.svg';
import { Cell, Column, TableState } from 'react-table';
import { breakdownTableNameWithAvatarCell } from 'tableUtils/tableCells';
import { GroupMember } from 'types/cohort';
import { PrivacyLevel } from 'types/dashboardUser';
import {
  scaledHrv, roundedNumber, roundedPercent, checkOptInInfoCell,
} from './cells';

export interface RecoveryBreakdownData {
  user_id?: number;
  first_name: string;
  last_name: string;
  avatar_url?: string;
  member_identifier?: string;
  score?: number;
  hrv?: number;
  rhr?: number;
  opted_in?: boolean;
  hide_average?: boolean;
}

type ColumnName = 'name' | 'score' | 'hrv' | 'rhr';

const columns: Record<ColumnName, Column<RecoveryBreakdownData>> = {
  name: {
    Header: 'Name',
    accessor: (data) => (data.first_name + data.last_name).toUpperCase(),
    id: 'name',
    Cell: breakdownTableNameWithAvatarCell,
  },
  score: {
    Header: 'Score',
    accessor: 'score',
    Cell: (cell: Cell<RecoveryBreakdownData>) => checkOptInInfoCell(
      roundedPercent(cell),
      cell.row.original?.opted_in,
    ),
  },
  hrv: {
    Header: 'HRV',
    accessor: 'hrv',
    Cell: (cell: Cell<RecoveryBreakdownData>) => checkOptInInfoCell(
      scaledHrv(cell),
      cell.row.original?.opted_in,
    ),
  },
  rhr: {
    Header: 'RHR',
    accessor: 'rhr',
    Cell: (cell: Cell<RecoveryBreakdownData>) => checkOptInInfoCell(
      roundedNumber(cell),
      cell.row.original?.opted_in,
    ),
  },
};

export function getRecoveryTableColumns(privacyLevel: PrivacyLevel) {
  switch (privacyLevel) {
    case PrivacyLevel.all_metrics:
    case PrivacyLevel.performance_metrics:
      return [
        columns.name,
        columns.score,
        columns.hrv,
        columns.rhr,
      ];
    case PrivacyLevel.primary_metrics:
      return [
        columns.name,
        columns.score,
      ];
    default:
      return [];
  }
}

function createMap<T extends BreakdownMetricType>(dto: BreakdownDTO<T>) {
  return dto.user_data.reduce(
    (map, data) => map.set(data.user_id, data.data_value),
    new Map<number, number | null>(),
  );
}

function createRecoveryBreakdownData(groupMembers: GroupMember[], score: BreakdownDTO<'recoveryScore'>, hrv?: BreakdownDTO<'hrv'>, rhr?: BreakdownDTO<'rhr'>) {
  if (groupMembers.length < 1) {
    return [];
  }

  const groupAverage: RecoveryBreakdownData = {
    first_name: 'Team',
    last_name: 'Average',
    avatar_url: TeamAvatar,
    hide_average: score.hide_average
    && hrv.hide_average
    && rhr.hide_average,
    score: score.average,
    hrv: hrv?.average,
    rhr: rhr?.average,
  };

  /**
     * Create maps of userId -> metric
     * This allows us to join the data together efficiently
     */
  const scoreMap = createMap(score);
  const hrvMap = hrv ? createMap(hrv) : null;
  const rhrMap = rhr ? createMap(rhr) : null;

  const userRows: RecoveryBreakdownData[] = groupMembers.map((m) => ({
    user_id: m.user_id,
    first_name: m.first_name,
    last_name: m.last_name,
    avatar_url: m.avatar_url,
    member_identifier: m.member_identifier,
    score: scoreMap.get(m.user_id),
    hrv: hrvMap?.get(m.user_id),
    rhr: rhrMap?.get(m.user_id),
    opted_in: m.opted_in,
  }));

  return [groupAverage, ...userRows];
}

export async function getRecoveryData(
  groupId: number,
  start: Date,
  end: Date,
  privacyLevel: PrivacyLevel,
) {
  switch (privacyLevel) {
    case PrivacyLevel.all_metrics:
    case PrivacyLevel.performance_metrics: {
      const metrics = await Promise.all([
        getGroupMembers(groupId),
        getRecoveryScoreBreakdown(groupId, start, end),
        getHrvBreakdown(groupId, start, end),
        getRhrBreakdown(groupId, start, end),
      ]);

      return createRecoveryBreakdownData(...metrics);
    }
    case PrivacyLevel.primary_metrics: {
      const metrics = await Promise.all([
        getGroupMembers(groupId),
        getRecoveryScoreBreakdown(groupId, start, end),
      ]);

      return createRecoveryBreakdownData(...metrics);
    }
    default:
      return [];
  }
}

export const initialRecoveryTableState: Partial<TableState> = {
  sortBy: [{ id: 'score', desc: true }],
};
