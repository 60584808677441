import { Dropdown } from '@whoop/web-components';
import { cohortClient } from 'api';
import { useAccounts } from 'context/account-context';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Cohort } from 'types/cohort';

type GroupsDropdownProps = {
  groupSelected: Cohort;
  setGroupSelected: (group: Cohort) => void;
  className?: string;
};

export const PRIMARY_GROUP_NAME = 'All Groups';

function GroupsDropdown({
  groupSelected, setGroupSelected, className,
}: GroupsDropdownProps) {
  const [searchParams] = useSearchParams();
  const { account } = useAccounts();
  const [groups, setGroups] = useState<Cohort[]>();

  useEffect(() => {
    const init = async () => {
      const data: Cohort[] = await cohortClient.getGroupsByUserAccess(true);
      const primaryGroup = data.find((group: Cohort) => group.is_primary);
      let groupToSelect;
      let groupsData;
      if (primaryGroup) {
        // Rename primary group
        primaryGroup.name = PRIMARY_GROUP_NAME;
        // Remove primary group from array and then add it back at the front
        // so it's at the top of the dropdown list
        const dataWithoutPrimary = data.filter((group: Cohort) => !group.is_primary);
        dataWithoutPrimary.unshift(primaryGroup);
        groupToSelect = primaryGroup;
        groupsData = dataWithoutPrimary;
      } else {
        const [destructuredFirstData] = data;
        groupToSelect = destructuredFirstData;
        groupsData = data;
      }
      if (searchParams.get('groupId')) {
        const loadedGroup = data.find((group: Cohort) => group.id === parseInt(searchParams.get('groupId'), 10));
        groupToSelect = loadedGroup;
      }
      setGroupSelected(groupToSelect);
      setGroups(groupsData);
    };
    if (account) {
      init();
    }
    return () => {
      setGroupSelected(null);
      setGroups(null);
    };
  }, [account]);

  const handleGroupSelect = (groupName: string) => {
    setGroupSelected(groups.find((group) => group.name === groupName));
  };

  return (
    <Dropdown
      data-testid="groups-dropdown"
      options={groups?.map((group) => group.name)}
      onOptionSelected={handleGroupSelect}
      selectedOption={groupSelected?.name || 'Loading...'}
      theme="dark"
      className={className}
    />
  );
}

export default GroupsDropdown;
