import { useState } from 'react';
import { Hub, HubParams } from 'types/hub';
import { useParams } from 'react-router-dom';
import { SUBTITLE_1, TITLE_3 } from 'components/text';
import { useAccounts } from 'context/account-context';
import { Icon, StatusTag } from '@whoop/web-components';
import { DashboardRole } from 'types/dashboardUser';
import EditableHeaderInput from 'components/editableHeaderInput/editableHeaderInput';
import styles from './hubHeader.module.scss';

type HubHeaderTypes = {
  currentHub: Hub;
  handleUpdateHub: (property: keyof Hub, value: string) => void;
};

function HubHeader({ currentHub, handleUpdateHub }: HubHeaderTypes) {
  const { macAddress } = useParams<HubParams>();
  const { checkAccess } = useAccounts();
  const [description, setDescription] = useState<string>(currentHub.description);
  const [name, setName] = useState(currentHub.name);

  return (
    <div className={styles.hubDetailsHeader}>
      <div className={styles.titleRow}>
        {checkAccess(DashboardRole.ACCOUNT_MANAGER)
          ? (
            <EditableHeaderInput
              onUpdate={handleUpdateHub}
              field="name"
              value={name}
              setValue={setName}
              placeholder="Give this hub a name"
            />
          )
          : <TITLE_3>{currentHub.name}</TITLE_3>}
        <StatusTag
          color="gray"
          theme="default"
          className={styles.macAddress}
        >
          {`MAC ADDRESS ${macAddress}`}
        </StatusTag>
      </div>
      {checkAccess(DashboardRole.ACCOUNT_MANAGER)
        ? (
          <EditableHeaderInput
            onUpdate={handleUpdateHub}
            field="description"
            value={description}
            setValue={setDescription}
            placeholder="Add a description to this hub"
          />
        )
        : <SUBTITLE_1 className={styles.description}>{currentHub.description}</SUBTITLE_1>}
      <div className={styles.invitedCollaboratorsContainer}>
        <Icon name="locked" />
        <p>Only invited collaborators can access</p>
      </div>
    </div>
  );
}

export default HubHeader;
