import { DashboardRole, PrivacyLevel } from '../types/dashboardUser';

export enum AccountDetailsTab {
  SUMMARY = 'summary',
  SETTINGS = 'settings',
  PERMISSIONS = 'permissions',
}

export type AccountDetailsParams = {
  accountId: string;
  tabTitle: AccountDetailsTab;
};

export enum AccountConfigurationSettingType {
  Toggle = 'toggle',
  Button = 'button',
}

export const defaultRoleOptions = [
  {
    name: DashboardRole.GROUP_MANAGER,
    active: false,
    description: 'Group Managers can access specific group(s) assigned to them by an Account Admin or Account Manager.',
    value: DashboardRole.GROUP_MANAGER,
  },
];

export const PRIVACY_LEVEL_NAME_MAP = {
  [PrivacyLevel.no_metrics]: 'No Metrics',
  [PrivacyLevel.aggregate_metrics]: 'Aggregate Metrics',
  [PrivacyLevel.primary_metrics]: 'Primary Metrics',
  [PrivacyLevel.performance_metrics]: 'Performance Metrics',
  [PrivacyLevel.all_metrics]: 'All Metrics',
};

export const PRIVACY_LEVEL_NAME_TO_LEVEL = {
  'No Metrics': PrivacyLevel.no_metrics,
  'Aggregate Metrics': PrivacyLevel.aggregate_metrics,
  'Primary Metrics': PrivacyLevel.primary_metrics,
  'Performance Metrics': PrivacyLevel.performance_metrics,
  'All Metrics': PrivacyLevel.all_metrics,
};

export const privacyLevelOptions = [
  {
    name: PRIVACY_LEVEL_NAME_MAP[PrivacyLevel.all_metrics],
    active: false,
    value: PrivacyLevel.all_metrics,
    description: (
      <ul>
        <li>All WHOOP data and metrics available</li>
        <li>Group and individual data</li>
      </ul>),
  },
  {
    name: PRIVACY_LEVEL_NAME_MAP[PrivacyLevel.performance_metrics],
    active: false,
    value: PrivacyLevel.performance_metrics,
    description: (
      <ul>
        <li>Detailed Strain & Recovery metrics with limited Sleep data</li>
        <li>Group and individual data</li>
      </ul>),
  },
  {
    name: PRIVACY_LEVEL_NAME_MAP[PrivacyLevel.primary_metrics],
    active: false,
    value: PrivacyLevel.primary_metrics,
    description: (
      <ul>
        <li>Main pillar metrics: Strain, Recovery, and Sleep</li>
        <li>Group and individual data</li>
      </ul>),
  },
  {
    name: PRIVACY_LEVEL_NAME_MAP[PrivacyLevel.aggregate_metrics],
    active: false,
    value: PrivacyLevel.aggregate_metrics,
    description: (
      <ul>
        <li>Main pillar metrics: Strain, Recovery, and Sleep</li>
        <li>Group averages only</li>
      </ul>),
  },
  {
    name: PRIVACY_LEVEL_NAME_MAP[PrivacyLevel.no_metrics],
    active: true,
    value: PrivacyLevel.no_metrics,
    description: 'No access to any data or metrics. Collaborators will only be able to manage members in groups',
  },
];
