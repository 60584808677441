import { Colors } from '@whoop/web-components';

export type PieChartLabel = {
  key: string;
  color: string;
  label: string;
};

// These arrays are ordered in the order that the pie chart is displayed in
// This ordering is also tied to pie chart labels which uses the reverse order
// That will need to be updated if this ordering changes
export const PieChartLabels = {
  RECOVERY: [
    {
      key: 'red',
      color: Colors.brandingRed,
      label: 'Red (< 33%)',
    },
    {
      key: 'yellow',
      color: Colors.yellow,
      label: 'Yellow (34% - 66%)',
    },
    {
      key: 'green',
      color: Colors.green,
      label: 'Green (67%+)',
    },
  ],
  STRAIN: [
    {
      key: 'light',
      color: Colors.strainBlueAlpha500,
      label: 'Light (< 10.0)',
    },
    {
      key: 'moderate',
      color: Colors.strainBlueAlpha700,
      label: 'Moderate (10.1 - 14.0)',
    },
    {
      key: 'strenuous',
      color: Colors.strainBlueAlpha850,
      label: 'Strenuous (14.1 - 18.0)',
    },
    {
      key: 'all_out',
      color: Colors.strainBlue,
      label: 'All out (18.0+)',
    },
  ],
  SLEEP_PERFORMANCE: [
    {
      key: 'poor',
      color: Colors.sleepBlueAlpha300,
      label: 'Poor (< 70%)',
    },
    {
      key: 'sufficient',
      color: Colors.sleepBlueAlpha600,
      label: 'Sufficient (70% - 85%)',
    },
    {
      key: 'optimal',
      color: Colors.sleepBlue,
      label: 'Optimal (85%+)',
    },
  ],
  SLEEP_DEBT: [
    {
      key: 'low',
      color: Colors.sleepBlueAlpha300,
      label: 'Low (< 30 mins)',
    },
    {
      key: 'moderate',
      color: Colors.sleepBlueAlpha600,
      label: 'Moderate (30-45 mins)',
    },
    {
      key: 'high',
      color: Colors.sleepBlue,
      label: 'High (45+ mins)',
    },
  ],
};
