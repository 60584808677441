import Logo from 'assets/ui-logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileMenu from 'components/profileMenu';
import { useAccounts } from 'context/account-context';
import { useFeatureFlags } from 'components/featureFlag/feature-flag-context';
import { useEffect, useMemo, useState } from 'react';
import styles from './navigationBar.module.scss';
import Tabs, { TabThemes } from '../tabs/tabs';

enum HeaderNavTabs {
  PROGRESS = 'progress',
  INSIGHTS = 'insights',
  PEOPLE = 'people',
  PLAN = 'plan',
}

enum ProgressNavLink {
  PROGRESS = 'progress',
  INSIGHTS = 'insights',
}

function NavigationBar() {
  const { account } = useAccounts();
  const location = useLocation();
  const { getFeatureFlag } = useFeatureFlags();
  const navigate = useNavigate();
  const [showInsightsPage, setShowInsightsPage] = useState(false);

  let tabSelected = HeaderNavTabs.PEOPLE;
  const splitPathname = location.pathname.split('/');
  if (splitPathname.length > 3) {
    tabSelected = splitPathname[3] as HeaderNavTabs;
  }
  const getShowInsightsPage = async () => {
    const data = await getFeatureFlag('unite-team-level-dashboard');
    setShowInsightsPage(data);
  };

  useEffect(() => {
    getShowInsightsPage();
  }, []);

  const tabs = useMemo(() => {
    const tabList = [{ tab: HeaderNavTabs.PROGRESS, nav: ProgressNavLink.PROGRESS }, { tab: HeaderNavTabs.PEOPLE, nav: 'people/members' }, { tab: HeaderNavTabs.PLAN, nav: 'plan/summary' }];

    // Add insights tab if on FF
    if (showInsightsPage) {
      tabList.unshift({ tab: HeaderNavTabs.INSIGHTS, nav: ProgressNavLink.INSIGHTS });
    }
    return tabList;
  }, [showInsightsPage]);

  const isAdminPage = location.pathname === '/accounts';

  const handleTabClick = (tab: HeaderNavTabs) => {
    const tabToNavigate = tabs.filter((t) => t.tab === tab)[0];
    navigate(`/accounts/${account.id}/${tabToNavigate.nav}`);
  };

  return (
    <div
      className={styles.navContainer}
    >
      <div className={styles.left}>
        <div className={styles.headerLogo}>
          <img className={styles.whoopLogo} src={Logo} alt="Whoop logo" />
        </div>
      </div>
      {!isAdminPage && (
        <div className={styles.tabs}>
          <Tabs
            tabList={tabs}
            selectedTab={tabSelected}
            onClick={(tab) => handleTabClick(tab)}
            theme={TabThemes.NAVIGATION}
          />
        </div>

      )}
      <div className={styles.right}>
        <ProfileMenu />
      </div>
    </div>
  );
}

export default NavigationBar;
