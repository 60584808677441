import { optOutIconCell } from 'tableUtils/tableCells';
import { ReactNode } from 'react';

type Cell = {
  value: number;
};

export function roundedNumber({ value }: Cell) {
  return value ? Math.round(value) : null;
}

export function roundedPercent(cell: Cell) {
  const rounded = roundedNumber(cell);
  return rounded ? `${rounded}%` : null;
}

export function scaledHrv({ value }: Cell) {
  const scaled = value ? value * 1000 : null;
  return roundedNumber({ value: scaled });
}

export function scaledSleepEfficiency({ value }: Cell) {
  const scaled = value ? value * 100 : null;
  return roundedPercent({ value: scaled });
}

export function checkOptInInfoCell(
  cellComponent: ReactNode,
  opt_in: boolean,
) {
  if (opt_in === false) {
    return optOutIconCell();
  }
  return cellComponent;
}
