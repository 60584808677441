import { useQuery, UseQueryResult } from '@tanstack/react-query';
import {
  getWidgetData, SomeWidget,
} from 'api/widgetApi';
import { WidgetType } from 'insights/components/widget/widget';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import { formatDate } from 'progress/profile/profileUtils';
import { useParams } from 'react-router-dom';

export function useWidgetQuery<T extends WidgetType>(
  widgetType: T,
): UseQueryResult<SomeWidget<T>, unknown> {
  const { groupId } = useParams<{ groupId: string }>();
  const { dateRange } = useDateRange();
  const params = {
    cohortId: groupId,
    startDate: formatDate(dateRange.from),
    endDate: formatDate(dateRange.to ?? dateRange.from),
  };

  const queryParams = {
    queryKey: ['getWidgetQuery', widgetType, params],
    queryFn: () => getWidgetData(widgetType, params),
  };

  return useQuery(queryParams);
}
