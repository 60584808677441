import NoData from 'assets/icons/noData.svg';
import { DEFAULT_PILLAR_METRIC_TYPE, Pillars } from 'types/analytics';
import useMetric from 'api/hooks/useMetric';
import { useDateRange } from 'progress/profile/hooks/useDateRange';
import { Row, Col } from '@whoop/web-components';
import styles from './noDataBanner.module.scss';

type NoDataBannerProps = {
  pillar: Pillars;
};

function NoDataBanner({ pillar }: NoDataBannerProps) {
  const { dateRange } = useDateRange();
  const { data, isLoading } = useMetric(
    DEFAULT_PILLAR_METRIC_TYPE[pillar],
    dateRange,
  );

  if (!isLoading && data?.average_value == null) {
    return (
      <Row>
        <Col xs={12}>
          <div className={styles.noDataInfo}>
            <img className={styles.noDataIcon} src={NoData} alt="No data" />
            No data received on this day. Please make sure this member wears their WHOOP strap
            properly.
          </div>
        </Col>
      </Row>
    );
  }
  return null;
}

export default NoDataBanner;
