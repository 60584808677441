import { Colors, Icon } from '@whoop/web-components';
import { SENTIMENT } from 'api/groupLandingApi';
import styles from './trendIndicatorBox.module.scss';

type TrendCompProps = {
  diff: number;
  parsedDiff: string;
  trend: SENTIMENT;
};

const getTrendColor = (trend: SENTIMENT) => {
  switch (trend) {
    case SENTIMENT.POSITIVE:
      return {
        background: Colors.greenTrendAlpha150,
        color: Colors.teal,
      };
    case SENTIMENT.NEGATIVE:
      return {
        background: Colors.yellowTrendAlpha150,
        color: Colors.trendYellow,
      };
    default:
      return {
        background: Colors.whiteAlpha100,
        color: Colors.whiteAlpha700,
      };
  }
};

function TrendIndicatorBox({ diff, parsedDiff, trend }: TrendCompProps) {
  const { background, color } = getTrendColor(trend);
  return (
    <div className={styles.trendBox} style={{ backgroundColor: background, color }}>
      {!!diff && <Icon name={diff > 0 ? 'tiny_triangle_up' : 'tiny_triangle_down'} className={styles.trendIcon} />}
      <span>
        {parsedDiff}
      </span>
    </div>
  );
}

export default TrendIndicatorBox;
