import { PrivacyLevel } from './dashboardUser';

export type AdminAccountDetail = {
  active_seats: number;
  num_compliant: number;
  total_active_contracts: number;
  expiration_date: Date;
  id: number;
  account_name: string;
  total_licenses: number;
};

export enum SignupAccountType {
  NEW_ACCOUNT = 'newAccount',
  USER = 'user',
  STAFF = 'staff',
}

export interface SignupDetails {
  email: string;
  account_type: SignupAccountType;
  salesforce_account_name: string;
}

export interface AccountConfiguration {
  account_id: string;
  bluetooth_roaming: boolean;
  concealed: boolean;
  concealed_feature_enabled: boolean;
  opt_out_enabled: boolean;
  deidentified: boolean;
  hub_enabled: boolean;
  privacy_ceiling_level: PrivacyLevel;
  strap_onboarding_enabled: boolean;
}

export const reidentificationTokenKey = 'reidentificationToken';

export type ReidentificationTokenResponse = {
  user_id: number;
  salesforce_account_id: string;
  token: string;
  expiration_date: string;
  created_at: string;
};
