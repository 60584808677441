import { client } from 'api/apiClient';
import { WidgetType } from 'insights/components/widget/widget';

const accountUrl = 'enterprise-service/v1/card';

export type RecoveryBreakdown = {
  green: number;
  yellow: number;
  red: number;
};

export type SleepPerformanceBreakdown = {
  optimal: number;
  sufficient: number;
  poor: number;
};

export type SleepDebtBreakdown = {
  high: number;
  moderate: number;
  low: number;
};

export type StrainBreakdown = {
  all_out: number;
  strenuous: number;
  moderate: number;
  light: number;
};

export interface RecoveryBucket extends RecoveryBreakdown {
  date: string;
}

export interface StrainBucket extends StrainBreakdown {
  date: string;
}

export interface SleepPerformanceBucket extends SleepPerformanceBreakdown {
  date: string;
}

export interface SleepDebtBucket extends SleepDebtBreakdown {
  date: string;
}

interface BaseLeaderboard {
  user_id: number;
  name: string;
  avatar_url: string;
}

export interface RecoveryLeaderboard extends BaseLeaderboard {
  hrv: number;
  rhr: number;
  recovery: number;
}

export interface StrainLeaderboard extends BaseLeaderboard {
  calories: number;
  hr: number;
  strain: number;
}

export interface SleepLeaderboard extends BaseLeaderboard {
  sleep_duration_minutes: number;
  sleep_performance: number;
  sleep_debt_minutes: number;
}

interface BaseWidget {
  group_id: number;
  start_date: string;
  end_date: string;
  vow: string;
}

export interface RecoveryWidget extends BaseWidget {
  recovery_average: number;
  breakdown: RecoveryBreakdown;
  leaderboard: RecoveryLeaderboard[];
  buckets: RecoveryBucket[];
}

export interface StrainWidget extends BaseWidget {
  strain_average: number;
  breakdown: StrainBreakdown;
  leaderboard: StrainLeaderboard[];
  buckets: StrainBucket[];
}

export interface SleepPerformanceWidget extends BaseWidget {
  sleep_performance_average: number;
  breakdown: SleepPerformanceBreakdown;
  leaderboard: SleepLeaderboard[];
  buckets: SleepPerformanceBucket[];
}

export interface SleepDebtWidget extends BaseWidget {
  sleep_debt_average_minutes: number;
  breakdown: SleepDebtBreakdown;
  leaderboard: SleepLeaderboard[];
  buckets: SleepDebtBucket[];
}

// this SomeWidget type is a conditional type used
// to infer the return type based on the parameter given
// if you wanted to create a new widget you would need to add it to WidgetType
// and create a new check to see if T extends that type
export type SomeWidget<T extends WidgetType> = T extends WidgetType.RECOVERY
  ? RecoveryWidget
  : T extends WidgetType.SLEEP_DEBT
    ? SleepDebtWidget
    : T extends WidgetType.SLEEP_PERFORMANCE
      ? SleepPerformanceWidget
      : T extends WidgetType.DAY_STRAIN
        ? StrainWidget
        : never;

export const getWidgetData = async <T extends WidgetType>(
  statistic: T,
  params: { cohortId: string; startDate: string; endDate: string },
) => {
  const response = await client.get<SomeWidget<T>>(`${accountUrl}/${statistic}`, { params });
  return response.data;
};
