import { useMemo } from 'react';
import {
  Column, Hooks, TableInstance, useTable,
} from 'react-table';
import { IconCell, TextCell } from 'tableUtils/tableCells';
import { PrivacyLevel } from 'types/dashboardUser';

export enum PrivacyLevelIndicator {
  CHECK = 'check',
  AGGREGATE = 'community',
  CROSS = 'cross',
}

export type PrivacyLevelInfoRow = {
  data_type: string;
  all_metrics: PrivacyLevelIndicator;
  performance_metrics: PrivacyLevelIndicator;
  primary_metrics: PrivacyLevelIndicator;
  aggregate_metrics: PrivacyLevelIndicator;
  no_metrics: PrivacyLevelIndicator;
};

export type PrivacyLevelTableInstance = TableInstance<PrivacyLevelInfoRow>
& UseSelectedColumnInstanceProps;

export type UseSelectedColumnInstanceProps = {
  selectedCol: PrivacyLevel;
};

export const data: PrivacyLevelInfoRow[] = [
  {
    data_type: 'Strain, Recovery, Sleep Performance',
    all_metrics: PrivacyLevelIndicator.CHECK,
    performance_metrics: PrivacyLevelIndicator.CHECK,
    primary_metrics: PrivacyLevelIndicator.CHECK,
    aggregate_metrics: PrivacyLevelIndicator.AGGREGATE,
    no_metrics: PrivacyLevelIndicator.CROSS,
  },
  {
    data_type: 'Hours of Sleep, Sleep Debt',
    all_metrics: PrivacyLevelIndicator.CHECK,
    performance_metrics: PrivacyLevelIndicator.CHECK,
    primary_metrics: PrivacyLevelIndicator.CHECK,
    aggregate_metrics: PrivacyLevelIndicator.AGGREGATE,
    no_metrics: PrivacyLevelIndicator.CROSS,
  },
  {
    data_type: 'Heart Rate, HRV, RHR, Calories, Respiratory Rate',
    all_metrics: PrivacyLevelIndicator.CHECK,
    performance_metrics: PrivacyLevelIndicator.CHECK,
    primary_metrics: PrivacyLevelIndicator.CROSS,
    aggregate_metrics: PrivacyLevelIndicator.CROSS,
    no_metrics: PrivacyLevelIndicator.CROSS,
  },
  {
    data_type: 'Activities',
    all_metrics: PrivacyLevelIndicator.CHECK,
    performance_metrics: PrivacyLevelIndicator.CHECK,
    primary_metrics: PrivacyLevelIndicator.CROSS,
    aggregate_metrics: PrivacyLevelIndicator.CROSS,
    no_metrics: PrivacyLevelIndicator.CROSS,
  },
  {
    data_type: 'Sleep Consistency, Sleep Efficiency',
    all_metrics: PrivacyLevelIndicator.CHECK,
    performance_metrics: PrivacyLevelIndicator.CROSS,
    primary_metrics: PrivacyLevelIndicator.CROSS,
    aggregate_metrics: PrivacyLevelIndicator.CROSS,
    no_metrics: PrivacyLevelIndicator.CROSS,
  },
];

export const PRIVACY_LEVEL_HEADING_NAME_MAP = {
  [PrivacyLevel.no_metrics]: 'No Metrics',
  [PrivacyLevel.aggregate_metrics]: 'Aggregate',
  [PrivacyLevel.primary_metrics]: 'Primary',
  [PrivacyLevel.performance_metrics]: 'Performance',
  [PrivacyLevel.all_metrics]: 'All Metrics',
};

const useSelectedColumn = (hooks: Hooks<PrivacyLevelInfoRow>) => {
  hooks.useInstance.push((instance) => {
    Object.assign(instance, {
      selectedCol: instance.selectedCol,
    });
  });
};

function usePrivacyLevelTable(privacyLevel: PrivacyLevel) {
  const columns: Column<PrivacyLevelInfoRow>[] = useMemo(() => {
    const dataType = {
      id: 'data_type',
      Header: 'Data Type',
      accessor: 'data_type',
      Cell: TextCell,
    };

    const col = [
      dataType,
    ];

    [
      PrivacyLevel.all_metrics,
      PrivacyLevel.performance_metrics,
      PrivacyLevel.primary_metrics,
      PrivacyLevel.aggregate_metrics,
      PrivacyLevel.no_metrics,
    ].forEach((level) => col.push(
      {
        id: level,
        Header: PRIVACY_LEVEL_HEADING_NAME_MAP[level],
        accessor: level,
        Cell: IconCell,
      },
    ));

    return col as Column<PrivacyLevelInfoRow>[];
  }, [privacyLevel]);

  return {
    ...useTable(
      {
        columns,
        data,
        selectedCol: privacyLevel,
      },
      useSelectedColumn,
    ),
  } as PrivacyLevelTableInstance;
}

export default usePrivacyLevelTable;
