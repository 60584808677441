import { classes, Dropdown, Icon } from '@whoop/web-components';
import { PRIVACY_LEVEL_NAME_MAP, PRIVACY_LEVEL_NAME_TO_LEVEL } from 'accountDetails/types';
import { JobTitle } from 'api/jobTitleApi';
import { PrivacyLevel } from 'types/dashboardUser';
import styles from './jobTitleSettings.module.scss';

type JobTitleSettingsProps = {
  jobTitle: JobTitle;
  updateJobTitle: (jobTitle: JobTitle, updatedJobTitle: JobTitle) => void;
  deleteEnabled: boolean;
  addEnabled: boolean;
  onDelete: (jobTitle: JobTitle) => void;
  addJobTitle: () => void;
  jobTitleOptions: string[];
};

function JobTitleSettings({
  jobTitle, updateJobTitle, deleteEnabled, addEnabled, onDelete, addJobTitle, jobTitleOptions,
}: JobTitleSettingsProps) {
  const updateJobName = (selectedName: string) => {
    const updatedJobTitle = {
      ...jobTitle,
      job_title_name: selectedName,
    };
    updateJobTitle(jobTitle, updatedJobTitle);
  };

  const updatePrivacyLevel = (privacyLevel: keyof typeof PRIVACY_LEVEL_NAME_TO_LEVEL) => {
    const updatedJobTitle = {
      ...jobTitle,
      privacy_level: PRIVACY_LEVEL_NAME_TO_LEVEL[privacyLevel],
    };
    updateJobTitle(jobTitle, updatedJobTitle);
  };

  return (
    <div className={styles.jobTitleRow}>
      <Dropdown
        className={styles.dropdown}
        data-testid="jobTitleDropdown"
        options={jobTitleOptions}
        onOptionSelected={updateJobName}
        selectedOption={jobTitle.job_title_name}
      />
      <Dropdown
        className={styles.dropdown}
        data-testid="privacyLevelDropdown"
        options={Object.keys(PRIVACY_LEVEL_NAME_MAP).map(
          (key: PrivacyLevel) => PRIVACY_LEVEL_NAME_MAP[key],
        )}
        onOptionSelected={updatePrivacyLevel}
        selectedOption={PRIVACY_LEVEL_NAME_MAP[jobTitle.privacy_level]}
      />
      <div className={styles.numUsersSection}>
        <Icon name="profile" className={styles.userIconSize} />
        {/* Future TODO : put the number of collaborators here */}
        <p className={styles.number}>{0}</p>
      </div>
      <div className={classes(styles.iconContainer, deleteEnabled && styles.showIcon)}>
        <Icon data-testid="deleteJobTitle" name="trash" className={styles.iconSize} onClick={() => onDelete(jobTitle)} />
      </div>
      <div className={classes(styles.iconContainer, addEnabled && styles.showIcon)}>
        <Icon data-testid="addJobTitle" name="add_circle" className={styles.iconSize} onClick={addJobTitle} />
      </div>
    </div>
  );
}

export default JobTitleSettings;
