import { BarDatum, BarItemProps, ResponsiveBar } from '@nivo/bar';
import { AxisTickProps } from '@nivo/axes';
import { LabeledBarComponent } from 'dataVisualizations/bars/bars';
import { useState } from 'react';
import { DayBarTooltip } from 'dataVisualizations/tooltips/tooltips';
import { DayYTick, DayMonthXAxisTick } from 'dataVisualizations/axis/axis';
import { BarData } from 'dataVisualizations/types/types';
import { timeAxisValues } from 'dataVisualizations/axis/utils';
import { SleepMetricDetailsType } from 'types/analytics';
import { SLEEP_COLOR_ZONES, SLEEP_STAGE_TITLE } from 'dataVisualizations/utils/sleep';

export const DEFAULT_KEY_VALUE = ['metric_value'];
const indexBy = 'cycle_start_day';
const layers = ['grid', 'bars', 'axes', 'markers'];

// can add more colors for different bar stacks here
const STACKED_BAR_CHART_COLORS = {
  ...SLEEP_COLOR_ZONES,
};

// can add more abrv for different bar stacks here
const KEY_TO_NAME = {
  ...SLEEP_STAGE_TITLE,
};

type DayBarChartProps = {
  data: BarData[];
  gridYValues: number[];
  color: string;
  metricName: string;
  keys?: string[];
  isStackedBarChart?: boolean;
  customLabelFormat?: (val: number) => string;
  customYAxisFormat?: (val: number) => string;
};

function DayBarChart({
  data,
  gridYValues,
  color,
  metricName,
  keys = DEFAULT_KEY_VALUE,
  isStackedBarChart = false,
  customLabelFormat,
  customYAxisFormat,
}: DayBarChartProps) {
  const [dayHighlighted, setDayHighlighted] = useState(null);
  const dateAxisValues = timeAxisValues(
    data[0].cycle_start_day,
    data[data.length - 1].cycle_start_day,
  );

  return (
    <ResponsiveBar
      data={data}
      keys={keys}
      indexBy={indexBy}
      margin={{
        top: 30, right: 0, bottom: 50, left: 36,
      }}
      minValue={0}
      maxValue={gridYValues[gridYValues.length - 1]}
      enableLabel
      enableGridX={false}
      enableGridY
      // @ts-ignore
      layers={layers}
      isInteractive
      padding={0.7}
      gridYValues={gridYValues}
      axisLeft={{
        tickValues: gridYValues,
        tickSize: 0,
        renderTick: (
          tick: AxisTickProps<number>,
        ) => DayYTick(tick.x, tick.y, tick.value, customYAxisFormat),
      }}
      colors={color}
      axisBottom={{
        tickValues: dateAxisValues,
        tickSize: 0,
        renderTick: (tick: AxisTickProps<string>) => DayMonthXAxisTick(tick, true),
      }}
      tooltip={(node) => DayBarTooltip(
        node,
        metricName,
        keys,
        data.length - 1,
        customLabelFormat,
        KEY_TO_NAME,
        STACKED_BAR_CHART_COLORS,
      )}
      barComponent={
        (barComponent: BarItemProps<BarDatum>) => LabeledBarComponent(
          barComponent.bar,
          barComponent.tooltip,
          dayHighlighted,
          setDayHighlighted,
          data.length - 1,
          metricName,
          // cast Id as SleepMetricDetailsType since
          // it's the only component that uses the isStackedBarChart property
          // for future usecases the MetricDetails Type should be expanded
          isStackedBarChart
            ? STACKED_BAR_CHART_COLORS[barComponent.bar?.data?.id as SleepMetricDetailsType]
            : color,
          // need to know which layer of the stack in order to create the gaps between bars
          keys.indexOf(barComponent.bar?.data?.id as string),
          customLabelFormat,
        )
      }
    />
  );
}

export default DayBarChart;
