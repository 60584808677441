import {
  StrainWidget, SleepPerformanceWidget,
  RecoveryWidget, SleepDebtWidget,
} from 'api/widgetApi';

export const mockRecoveryWidgetResponse: RecoveryWidget = {
  group_id: 42408,
  start_date: '2023-06-05',
  end_date: '2023-06-05',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  recovery_average: 89.0,
  breakdown: { green: 1, yellow: 0, red: 0 },
  leaderboard: [
    {
      user_id: 30991,
      name: '10GOLCI',
      avatar_url: '',
      hrv: 97.53440320491791,
      rhr: 54.0,
      recovery: 89.0,
    },
  ],
  buckets: [
    {
      date: '2023-06-05',
      green: 1,
      yellow: 0,
      red: 0,
    },
  ],
};

export const mockSleepPerformanceWidgetResponse: SleepPerformanceWidget = {
  group_id: 42408,
  start_date: '2023-06-14',
  end_date: '2023-06-14',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  sleep_performance_average: 88.0,
  breakdown: {
    optimal: 1,
    sufficient: 0,
    poor: 0,
  },
  leaderboard: [
    {
      user_id: 30991,
      name: '10GOLCI',
      avatar_url: '',
      sleep_duration_minutes: 458.05095,
      sleep_performance: 88.0,
      sleep_debt_minutes: 36.03968333333333,
    },
  ],
  buckets: [
    {
      date: '2023-06-14',
      optimal: 1,
      sufficient: 0,
      poor: 0,
    },
  ],
};

export const mockSleepDebtWidgetResponse: SleepDebtWidget = {
  group_id: 218088,
  start_date: '2023-06-21',
  end_date: '2023-06-21',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  sleep_debt_average_minutes: 66.4153388888889,
  breakdown: {
    high: 2,
    moderate: 3,
    low: 4,
  },
  leaderboard: [
    {
      user_id: 30991,
      name: '10GOLCI',
      avatar_url: '',
      sleep_duration_minutes: 479.19978333333336,
      sleep_performance: 93.0,
      sleep_debt_minutes: 22.62185,
    },
  ],
  buckets: [
    {
      date: '2023-06-21',
      high: 1,
      moderate: 0,
      low: 0,
    },
  ],
};
export const mockDayStrainWidgetResponse: StrainWidget = {
  group_id: 42408,
  start_date: '2023-06-14',
  end_date: '2023-06-14',
  vow: 'Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...',
  strain_average: 8.366140563286349,
  breakdown: {
    all_out: 0,
    strenuous: 0,
    moderate: 0,
    light: 1,
  },
  leaderboard: [
    {
      user_id: 30991,
      name: '10GOLCI',
      avatar_url: '',
      calories: 210.77189983418975,
      hr: 66.0,
      strain: 8.366140563286349,
    },
  ],
  buckets: [
    {
      date: '2023-06-14',
      all_out: 0,
      strenuous: 0,
      moderate: 0,
      light: 1,
    },
  ],
};
