import { MenuItem, MenuList } from '@material-ui/core';
import { ArrowDropDown } from '@material-ui/icons';
import CheckIcon from '@material-ui/icons/Check';
import { PRIVACY_LEVEL_NAME_MAP } from 'accountDetails/types';
import { dashboardRoleDisplay } from 'accountDetails/collaboratorsPage/roleDropdown/util';
import Dropdown from 'components/dropdown';
import { CellProps } from 'react-table';
import { Collaborator } from 'types/collaborator';
import { DashboardRole, PrivacyLevel } from 'types/dashboardUser';
import styles from './descriptionDropdown.module.scss';

export type DescriptionDropdownOption = {
  name: string;
  active: boolean;
  description: string | JSX.Element;
  value: PrivacyLevel | DashboardRole;
};

type DescriptionDropdownProps = {
  options: DescriptionDropdownOption[];
  cellProps: CellProps<Collaborator>;
  type: 'role' | 'privacy_level';
  field: string;
};

function DescriptionDropdown({
  options, cellProps, type, field,
}: DescriptionDropdownProps) {
  const { row } = cellProps;
  const role: string = dashboardRoleDisplay[row.original.role];

  const privacyLevel = PRIVACY_LEVEL_NAME_MAP[row.original.privacy_level];

  return (
    <div>
      <Dropdown
        button={(
          <p className={styles.privacyLevel}>
            {type === 'privacy_level' ? privacyLevel : role}
            <ArrowDropDown />
          </p>
        )}
        buttonProps={{
          variant: 'link',
          className: styles.dropdownBtn,
          'aria-label': `${type}_btn`,
        }}
        dropdownStyles={{ borderRadius: '8px', width: '396px' }}
        closeOnClick
      >
        <MenuList disablePadding>
          {options.map((item) => {
            // @ts-ignore
            const original = row.original[field];
            let active = false;
            if (original === DashboardRole.ACCOUNT_MANAGER) {
              active = item.value === original && item.name !== DashboardRole.ACCOUNT_ADMIN;
            } else {
              active = item.value === original;
            }

            return (
              <MenuItem
                key={`menu-item-${item.name}`}
                // @ts-ignore
                className={active
                  ? styles.activeItem
                  : styles.inactiveItem}
                onClick={() => cellProps.updateRow({
                  ...row.original,
                  [field]: item.value,
                })}
              >

                {active ? <CheckIcon /> : ''}
                <div>
                  <p className={styles.itemLabel}>
                    {type === 'role' ? dashboardRoleDisplay[item.name as DashboardRole] : item.name}
                  </p>
                  <div className={styles.itemDescription}>{item.description}</div>
                </div>
              </MenuItem>
            );
          })}
        </MenuList>
      </Dropdown>
    </div>
  );
}

export default DescriptionDropdown;
